<form [formGroup]="form" #f="ngForm"  (keydown.enter)="$event.preventDefault()">
    <div class=" card-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="nome" class="mb-1">Nome</label>
          <input [attr.disabled]="visualizar ? true : null" type="text" id="nome" class="form-control form-control-sm" formControlName="nome"
            maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
          <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
            <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="inputDescricao" class="mb-1">Descrição</label>
          <textarea [attr.disabled]="visualizar ? true : null" rows="3" id="inputDescricao" class="form-control form-control-sm"
            formControlName="descricao" maxlength="500" [ngClass]="{ 'is-invalid': f.submitted &&
            form.controls['descricao'].invalid }">
          </textarea>
          <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
            <div *ngIf="form.controls['descricao'].errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>
    </div>
  </form>