<div [class]="homologado ? '' : 'homologado'">
  <div *ngFor="let report of reportPEAs" [style.pageBreakBefore]="
          reportPEAs.indexOf(report) > 0 ? 'always' : 'avoid'
        ">
    <app-print-layout (cancelar)="cancelar()">
      <div class="relatorio content" *ngIf="report.nome">
        <div class="card card-outline">
          <div class="card-header d-flex pb-0" *ngIf="reportPEAs.indexOf(report) == 0 && consolidado">
            <h3>
              Relatório Consolidado - Período {{ report.periodo.nome }} -
              {{ report.periodo.dataInicio | date: "dd/MM/yyyy" }} a
              {{ report.periodo.dataFim | date: "dd/MM/yyyy" }}
            </h3>
          </div>

          <div class="card-body">
            <section id="header">
              <h3>PEAC - {{ report.nome }}</h3>
            </section>
            <hr />
            <section id="dados" class="no-break">
              <h4>Dados</h4>
              <div>
                <label>Período: </label>
                <span>{{ report.periodo.nome }}</span>
              </div>

              <div>
                <label>Unidade(s) Gestora(s): </label>
                <table>
                  <tr *ngFor="let ug of report.unidadeGestora">
                    <td>
                      {{ ug.sigla }} - {{ ug.nome }}
                    </td>
                  </tr>
                </table>
              </div>
              <br />
              <div>
                <label>Coordenação(ões): </label>
                <table>
                  <tr *ngFor="let pc of report.coordenacao">
                    <td>
                      {{ pc.sigla }} - {{ pc.nome }}
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <label>Auditores por Coordenação(ões): </label>
                <div id="auditores-cordenacoes" class="d-flex flex-wrap justify-content-between">
                  <table class="table col-6 mb-3" *ngFor="let pc of report.coordenacao">
                    <thead>
                      <tr>
                        <th>
                          {{ pc.sigla }} - {{ pc.nome }}
                        </th>
                      </tr>
                      <tr>
                        <th>Auditor</th>
                        <th class="text-center">Disponível para auditoria</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let a of pc.auditorCoordenacao" class="no-break">
                        <td>{{ a.nome }}</td>
                        <td class="text-center">{{ a.disponivelAuditoria === true ? 'Sim' : 'Não' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <hr />
            <section id="capacidadeTrabalho">
              <h4>Capacidade de Trabalho</h4>
              <div>
                <table mat-table [dataSource]="capacidadeDataSource" class="w-100" [hidden]="capacidadeSemDados">
                  <ng-container matColumnDef="coordenacao">
                    <th mat-header-cell *matHeaderCellDef>Coordenação</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.coordenacaoNome }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="diasDisponiveis">
                    <th mat-header-cell *matHeaderCellDef class="text-right">
                      Dias Disponíveis
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                      {{ element.diasDisponiveis }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="equipesDisponiveis">
                    <th mat-header-cell *matHeaderCellDef class="text-right">
                      Nº Equipes Disponíveis
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                      {{ element.equipesDisponiveis }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="diasPorAuditoria">
                    <th mat-header-cell *matHeaderCellDef class="text-right">
                      Quant. Dias <br />por Auditoria
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                      {{ element.diasPorAuditoria }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="auditoriasPorEquipe">
                    <th mat-header-cell *matHeaderCellDef class="text-right">
                      Nº Auditorias <br />por Equipe
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                      {{ element.auditoriasPorEquipe }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="capacidadeTotalCoordenacao">
                    <th mat-header-cell *matHeaderCellDef class="text-right">
                      Capacidade Total <br />por Coordenação
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                      {{ element.capacidadeTotalCoordenacao }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsCapacidade" class="bg-light"></tr>
                  <tr mat-row *matRowDef="
                        let row;
                        columns: columnsCapacidade;
                        when: !agrupadorCapacidade
                      "></tr>

                  <!-- Group header -->
                  <ng-container matColumnDef="groupHeader">
                    <td colspan="7" mat-cell *matCellDef="let groupBy">
                      <strong>Ano: {{ groupBy.ano }} </strong>
                    </td>
                  </ng-container>

                  <tr mat-row class="bg-light groupRow no-break" *matRowDef="
                        let row;
                        columns: ['groupHeader'];
                        when: agrupadorCapacidade
                      "></tr>

                  <!-- Group footer -->
                  <ng-container matColumnDef="groupFooterLabel">
                    <td colspan="5" mat-cell *matCellDef="let groupBy" class="text-right">
                      <strong>Capacidade de Trabalho Total no Ano:
                      </strong>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="groupFooterValue">
                    <td mat-cell *matCellDef="let groupBy" class="text-right">
                      <strong>{{ groupBy.capacidadeTotalAno }} </strong>
                    </td>
                  </ng-container>
                  <tr mat-row class="groupRow no-break" *matRowDef="
                        let row;
                        columns: [
                          'groupFooterLabel',
                          'groupFooterValue'
                        ];
                        when: subTotalCapacidade
                      "></tr>
                </table>
              </div>
            </section>
            <hr />

            <section id="indicadorCriterio" style="page-break-before: always;">
              <h4>Indicador e Critérios</h4>
              <div>
                <div>
                  <label>Indicador: </label>
                  <span>{{ report.indicador.nome }}</span>
                </div>

                <div class="criterio" *ngFor="let criterio of report.indicador.criterio">
                  <div class="row ml-1">
                    <div class="mr-2">
                      <label><strong>Nome do critério: </strong></label>
                      <span>{{ criterio.nome }}</span>
                    </div>
                    <div class="mr-2">
                      <label><strong>Peso: </strong></label>
                      <span>{{ criterio.peso }}</span>
                    </div>
                    <div class="mr-2">
                      <label><strong>Tipo de Entrada: </strong></label>
                      <span>{{ criterio.tipoEntrada.nome }}</span>
                    </div>
                  </div>

                  <table class="table m-0" *ngIf="criterio.criterioMetrica.length > 0">
                    <thead>
                      <tr>
                        <th>Métrica</th>
                        <th class="text-center">Nota</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let metrica of criterio.criterioMetrica">
                        <td>
                          {{ metrica.operacao1.descricao }}
                          {{ metrica.valorNumerico1 | number: "1.2":"pt" }}
                          <span *ngIf="metrica.operacao2?.codigo > 0">{{ metrica.operacao2.descricao }}
                            {{
                            metrica.valorNumerico2 | number: "1.2":"pt"
                            }}</span>
                        </td>
                        <td class="text-center">{{ metrica.nota }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h4>Dados do(s) critério(s) e nota(s):</h4>
                <div class="d-flex flex-wrap justify-content-between">
                  <table class="table col-6 mb-3" *ngFor="let c of report.criteriosPorUnidadeGestoras">
                    <thead>
                      <tr>
                        <th>
                          {{ c.criterio.nome }}
                        </th>
                      </tr>
                      <tr>
                        <th>Unidade Gestora</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Nota</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let n of c.notasUnidadeGestoras">
                        <td>{{ n.sigla }}</td>
                        <td class="text-center">{{ n.valor | number: "1.2":"pt" }}</td>
                        <td class="text-center">{{ n.nota }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h4>Cálculo do Índice de Priorização:</h4>
                <div>
                  <table mat-table [dataSource]="distribuicaoDataSource" class="w-100" [hidden]="distruibuicaoSemDados">
                    <ng-container matColumnDef="codigoUG">
                      <th mat-header-cell *matHeaderCellDef align="center">
                        Código UG
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.codigo }}
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="unidadeGestora">
                      <th mat-header-cell *matHeaderCellDef align="center">
                        Unidade Gestora
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.sigla }}
                      </td>
                    </ng-container>

                    <div *ngFor="let coluna of colunasAdicionarDistribuicao">
                      <ng-container [matColumnDef]="coluna">
                        <th mat-header-cell *matHeaderCellDef class="text-center">
                          {{ coluna }} <br> <strong>{{ obterPesoColuna(coluna) }}</strong>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center">
                          {{ obterValorColuna(element, coluna) }}
                        </td>
                      </ng-container>
                    </div>

                    <ng-container matColumnDef="valorRisco">
                      <th mat-header-cell *matHeaderCellDef class="text-center">
                        Valor de Risco
                      </th>
                      <td mat-cell *matCellDef="let element" class="text-center">
                        {{ element.valorRisco }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsDistribuicao" class="bg-light"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsDistribuicao"></tr>
                  </table>
                </div>
              </div>
            </section>
            <hr />

            <section id="rankingUGs">
              <h4>Ranking das UGs com base no Índice de Priorização (Risco)</h4>

              <div class="col-md-12">
                <div class="tabela-ranking mat-elevation-z1">
                  <table mat-table [dataSource]="report.rankingPrioridade" class="w-100">
                    <ng-container matColumnDef="codigoUG">
                      <th colspan="2" mat-header-cell class="text-left" *matHeaderCellDef>
                        Código UG
                      </th>
                      <td colspan="2" mat-cell *matCellDef="let element" class="text-left">
                        {{ element.unidadeGestora.codigoUg }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="siglaUG">
                      <th colspan="2" mat-header-cell *matHeaderCellDef class="text-left">
                        Sigla UG
                      </th>
                      <td colspan="2" mat-cell *matCellDef="let element" class="text-left">
                        {{ element.unidadeGestora.sigla }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="nomeUG">
                      <th colspan="6" mat-header-cell *matHeaderCellDef class="text-left">
                        Nome UG
                      </th>
                      <td colspan="6" mat-cell *matCellDef="let element" class="text-left">
                        {{ element.unidadeGestora.nome }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="valorRisco">
                      <th colspan="2" mat-header-cell *matHeaderCellDef class="text-right">
                        Valor Risco
                      </th>
                      <td colspan="2" mat-cell *matCellDef="let element" class="text-right">
                        {{ element.valorRisco }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsRanking" class="bg-light"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsRanking"
                      [className]="'ranking-' + row?.blocoPainel?.nome"></tr>
                  </table>
                </div>

                <h4 class="my-2">Empresas Públicas</h4>

                <div class="mat-elevation-z1">
                  <table mat-table [dataSource]="report.rankingPrioridadeEmpresasPublicas" class="w-100">
                    <ng-container matColumnDef="codigoUG">
                      <th colspan="2" mat-header-cell class="text-left" *matHeaderCellDef>
                        Código UG (Dados consolidados na UG Mãe)
                      </th>
                      <td colspan="2" mat-cell *matCellDef="let element" class="text-left">
                        {{ element.unidadeGestora.codigoUg }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="siglaUG">
                      <th colspan="2" mat-header-cell *matHeaderCellDef class="text-center">
                        Sigla UG
                      </th>
                      <td colspan="2" mat-cell *matCellDef="let element" class="text-left">
                        {{ element.unidadeGestora.sigla }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="nomeUG">
                      <th colspan="6" mat-header-cell *matHeaderCellDef class="text-center">
                        Nome UG
                      </th>
                      <td colspan="6" mat-cell *matCellDef="let element" class="text-left">
                        {{ element.unidadeGestora.nome }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="valorRisco">
                      <th colspan="2" mat-header-cell *matHeaderCellDef class="text-right">
                        Valor Risco
                      </th>
                      <td colspan="2" mat-cell *matCellDef="let element" class="text-right">
                        {{ element.valorRisco }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsRanking" class="bg-light"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsRanking"
                      [className]="'ranking-' + row?.blocoPainel?.nome"></tr>
                  </table>
                </div>
              </div>
            </section>
            <hr />
            <section id="distribuicao" >
              <h4>
                Distribuição das auditorias por UG e Ano, conforme critério de
                priorização e faixa de risco inicialmente estabelecida
              </h4>

              <div>
                <div>
                  <h4 class="mb-1">Contempladas</h4>
                  <table class="distribuicao">
                    <tr>
                      <th *ngFor="let ano of report.anos">{{ ano }}</th>
                    </tr>

                    <tr *ngFor="
                          let distribuicao of report.dadosDistribuicaoContempladas
                        ">
                      <td *ngFor="let ano of report.anos" [className]="
                            'distribuicao-' + distribuicao[ano + '_class']
                          ">
                        <ng-container *ngIf="distribuicao[ano]">
                          {{ distribuicao[ano] }} <br /><small>({{ distribuicao[ano + "_indice"] }})</small>
                        </ng-container>
                      </td>
                    </tr>
                  </table>
                </div>

                <div>
                  <h4 class="mb-1">Não Contempladas</h4>
                  <table>
                    <tr>
                      <th *ngFor="let ano of report.anos">{{ ano }}</th>
                    </tr>

                    <tr *ngFor="
                          let distribuicao of report.dadosDistribuicaoNaoContempladas
                        ">
                      <td *ngFor="let ano of report.anos" [className]="
                            'distribuicao-' + distribuicao[ano + '_class']
                          ">
                        <ng-container *ngIf="distribuicao[ano]">
                          {{ distribuicao[ano] }} <br /><small>({{ distribuicao[ano + "_indice"] }})</small>
                        </ng-container>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </section>
            <hr *ngIf="report.classificacaoPersonalizada" />
            <section *ngIf="report.classificacaoPersonalizada" id="distribuicaoPersonalizada"
              style="page-break-before: always;">
              <h4 *ngIf="report.classificacaoPersonalizada">
                Distribuição das auditorias por UG e Ano, conforme critério de
                priorização e faixa de risco ajustada de acordo com a Capacidade
                de Trabalho
              </h4>

              <div *ngIf="report.classificacaoPersonalizada" class="row">
                <div class="col-3">
                  <label>Baixo: </label>
                  <span>{{ report.classificacaoPisoBaixo }} a
                    {{ report.classificacaoTetoBaixo }}</span>
                </div>
                <div class="col-3">
                  <label>Médio: </label>
                  <span>{{ report.classificacaoPisoMedio }} a
                    {{ report.classificacaoTetoMedio }}</span>
                </div>
                <div class="col-3">
                  <label>Alto: </label>
                  <span>{{ report.classificacaoPisoAlto }} a
                    {{ report.classificacaoTetoAlto }}</span>
                </div>
              </div>

              <div class="card-distrivuicao" *ngIf="report.classificacaoPersonalizada">
                <div>
                  <h4 class="mb-1">Contempladas</h4>
                  <table class="distribuicao">
                    <tr>
                      <th *ngFor="let ano of report.anos">{{ ano }}</th>
                    </tr>

                    <tr *ngFor="
                          let distribuicao of report.dadosDistribuicaoContempladasPersonalizado
                        ">
                      <td *ngFor="let ano of report.anos" [className]="
                            'distribuicao-' + distribuicao[ano + '_class']
                          ">
                        <ng-container *ngIf="distribuicao[ano]">
                          {{ distribuicao[ano] }} <br /><small>({{ distribuicao[ano + "_indice"] }})</small>
                        </ng-container>
                      </td>
                    </tr>
                  </table>
                </div>

                <div>
                  <h4 class="mb-1">Não Contempladas</h4>
                  <table>
                    <tr>
                      <th *ngFor="let ano of report.anos">{{ ano }}</th>
                    </tr>

                    <tr *ngFor="
                          let distribuicao of report.dadosDistribuicaoNaoContempladasPersonalizado
                        ">
                      <td *ngFor="let ano of report.anos" [className]="
                            'distribuicao-' + distribuicao[ano + '_class']
                          ">
                        <ng-container *ngIf="distribuicao[ano]">
                          {{ distribuicao[ano] }} <br /><small>({{ distribuicao[ano + "_indice"] }})</small>
                        </ng-container>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </section>
            <hr />
            <section style="page-break-before: always;" >
              <h4>Calendário</h4>
              <div class="my-3" *ngFor="let ano of report.anosCalendario">
                <h5>{{ ano.ano }}</h5>
                <table class="table table-striped table-bordered calendario m-0" *ngIf="ano.meses.length > 0">
                  <thead>
                    <tr>
                      <th>Meses/dias</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                      <th>13</th>
                      <th>14</th>
                      <th>15</th>
                      <th>16</th>
                      <th>17</th>
                      <th>18</th>
                      <th>19</th>
                      <th>20</th>
                      <th>21</th>
                      <th>22</th>
                      <th>23</th>
                      <th>24</th>
                      <th>25</th>
                      <th>26</th>
                      <th>27</th>
                      <th>28</th>
                      <th>29</th>
                      <th>30</th>
                      <th>31</th>
                      <th>Total de dias</th>
                      <th>Sab/Dom</th>
                      <th>Feriado</th>
                      <th>Ponto Facultativo</th>
                      <th>Dias úteis</th>
                      <th className="col-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let mes of ano.meses">
                      <td>{{ mes.nome }}</td>
                      <td *ngFor="let dia of mes.dias" [className]="
                            dia.sabadoDomingo && dia.feriado
                              ? 'feriado-SabadoDomingoFeriado'
                              : dia.sabadoDomingo
                              ? 'feriado-SabadoDomingo'
                              : dia.feriado
                              ? 'feriado-' + dia.feriado?.tipoFeriado?.nome
                              : ''
                          ">
                        <span *ngIf="!dia.diaMes">X</span>
                      </td>
                      <td>
                        {{ mes.totalDias }}
                      </td>
                      <td>
                        {{ mes.totalSabadosEDomingos }}
                      </td>
                      <td>
                        {{ mes.totalFeriados }}
                      </td>
                      <td>
                        {{ mes.totalPontosFacultativos }}
                      </td>
                      <td>
                        {{ mes.totalDiasUteis }}
                      </td>
                      <td>
                        {{ mes.feriados }}
                      </td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{dias}}</td>
                      <td>{{sabadoDomingo}}</td>
                      <td>{{feriado}}</td>
                      <td>{{pontoFacultativo}}</td>
                      <td>{{diasUteis}}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <table>
                <tr>
                  <td class="feriado-SabadoDomingo"></td>
                  <td>Sábados e domingos</td>
                </tr>
                <tr>
                  <td class="feriado-SabadoDomingoFeriado"></td>
                  <td>Sábado ou Domingo (com feriado)</td>
                </tr>
                <tr>
                  <td class="feriado-PontoFacultativo"></td>
                  <td>Ponto facultativo</td>
                </tr>
                <tr>
                  <td class="feriado-Nacional"></td>
                  <td>Feriados nacionais</td>
                </tr>
                <tr>
                  <td class="feriado-Estadual"></td>
                  <td>Feriados estaduais/municipais</td>
                </tr>
              </table>
            </section>
          </div>
          <div class="background" [ngClass]="{ homologado: homologado }"></div>
        </div>
      </div>
    </app-print-layout>
  </div>
</div>