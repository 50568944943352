import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { INiveisRiscoResidual } from 'src/app/models/niveis-risco-residual.model';
import { NiveisRiscoResidualService } from 'src/app/services/niveis-risco-residual.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-modal-risco-residual',
  templateUrl: './modal-risco-residual.component.html',
  styleUrls: ['./modal-risco-residual.component.scss']
})
export class ModalRiscoResidualComponent extends BaseFormComponent implements AfterViewInit, OnInit {

  model = {} as INiveisRiscoResidual;
  anoSelecionado: number;
  txtBtnConcluir: string;
  campoObrigatorio = false;
  atualizando = false;

  textoModal = 'Adicionar Classificação do Risco Residual'

  operadorList: any[] = [
    { id: '>=', nome: 'Maior ou Igual (>=)' },
    { id: '<=', nome: 'Menor ou Igual (<=)' },
    { id: '>', nome: 'Maior (>)' },
    { id: '<', nome: 'Menor (<)' }
  ];

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalRiscoResidualComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private serviceRiscoResidual: NiveisRiscoResidualService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);
    this.model.id = data.id;
    this.model.nome = data?.nome;
    this.model.operador = data?.operador;
    this.model.valor = data?.valor;
    this.model.operador_1 = data?.operador_1;
    this.model.valor_1 = data?.valor_1;
    this.model.ativo = data?.ativo;
    this.model.criteriosAvaliacaoRiscosId = data?.criteriosAvaliacaoRiscosId;

    if (!this.model.id || this.model.id == 0) {
      this.atualizando = false;
      this.txtBtnConcluir = "Inserir"
    }
    else {
      this.atualizando = true;
      this.txtBtnConcluir = "Atualizar"
      this.textoModal = 'Editar Classificação do Risco Residual'
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  concluir() {
    if (this.atualizando) {
      this.serviceRiscoResidual.atualizar(this.model).then((res) => { this.dialogRef.close(true); })
    } else {
      this.serviceRiscoResidual.inserir(this.model).then((res) => { this.dialogRef.close(true); })
    }
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
