import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IBaseModel } from '../models/base.model';
import { ICriteriosAvaliacaoRiscos } from '../models/criterios-avaliacao-riscos.model';

@Injectable({
  providedIn: 'root'
})
export class CriteriosAvaliacaoRiscoService extends BaseService{


  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<ICriteriosAvaliacaoRiscos[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<ICriteriosAvaliacaoRiscos[]>>(`${this.apiBaseUrl}/criteriosAvaliacaoRiscos`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<ICriteriosAvaliacaoRiscos>> {
    return this.httpClient
      .get<IBaseModel<ICriteriosAvaliacaoRiscos>>(`${this.apiBaseUrl}/criteriosAvaliacaoRiscos/${id}`)
      .toPromise();
  }

  public async inserir(data: ICriteriosAvaliacaoRiscos): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/criteriosAvaliacaoRiscos`, data)
      .toPromise();
  }

  public async atualizar(data: ICriteriosAvaliacaoRiscos): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/criteriosAvaliacaoRiscos/${data.id}`, data)
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/criteriosAvaliacaoRiscos/${id}/excluir`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/criteriosAvaliacaoRiscos/${id}/inativar`, {})
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/criteriosAvaliacaoRiscos/${id}/reativar`, {})
      .toPromise();
  }
}
