<div id="modal-content-a-equipe">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">{{textoModal}}</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancelar()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-md-3 was-validated"
                                [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                                <label for="inputOperador" class="mb-1">Campo 1</label>
                                <select id="inputOperador" #selectTipoTrabalho [(ngModel)]="model.campo_1"
                                    class="form-control form-control-sm" required>
                                    <option *ngFor="let item of riscoInerente" [ngValue]="item.nome">
                                        {{ item.nome }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div class="form-group col-md-2 was-validated"
                                [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                                <label for="inputOperador" class="mb-1">Operador</label>
                                <select id="inputOperador" #selectTipoTrabalho [(ngModel)]="model.operador"
                                    class="form-control form-control-sm" required>
                                    <option *ngFor="let item of operadorList" [ngValue]="item.id">
                                        {{ item.nome }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div class="form-group col-md-3 was-validated"
                                [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                                <label for="inputOperador_1" class="mb-1">Campo 2</label>
                                <select id="inputOperador_1" #selectTipoTrabalho [(ngModel)]="model.campo_2"
                                    class="form-control form-control-sm" required>
                                    <option *ngFor="let item of avaliacaoControle" [ngValue]="item.avaliacao">
                                        {{ item.avaliacao }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
                    <i class="fas fa-check"></i> {{txtBtnConcluir}}
                </button>

                <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                    <i class="fas fa-times"></i> Cancelar
                </button>
            </div>
        </div>
    </div>
</div>