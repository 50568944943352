import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-pdf-export',
  templateUrl: './pdf-export.component.html',
  styleUrls: ['./pdf-export.component.scss']
})
export class PdfExportComponent implements OnInit {
  @Input() fileName!: string;
  @Input() orientation: 'p' | 'l' = 'p'; // 'p' para retrato, 'l' para paisagem
  @Input() format: string = "a4";
  @Input() headerHeight: number = 30;
  @Input() footerHeight: number = 30;
  @Input() resizeTableSelectors?: string[];
  @Input() keepTogetherSelectors?: string[];
  @Input() showWatermark: false;

  @Output() cancelar = new EventEmitter<any>();

  constructor(
    public authService: AuthService,
    private loadingBar: LoadingBarService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  async generatePDF() {
    try {
      // Inicia os indicadores de carregamento
      this.loadingBar.start();
      this.spinner.show();

      const contentElement = document.querySelector('.content-pdf-export') as HTMLElement;
      if (contentElement) {
        const opt = {
          margin: [this.headerHeight, 0, this.footerHeight, 5],
          filename: this.fileName,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'mm', format: this.format, orientation: this.orientation },
          pagebreak: {
            mode: ['css'], // Modo para evitar quebras
            avoid: this.keepTogetherSelectors?.join(', ') // Seletores que devem evitar quebra
          }
        };

        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const pageWidthInPixels = (pageWidth * 96) / 25.4;

        const contentClone = contentElement.cloneNode(true) as HTMLElement;
        contentClone.style.maxWidth = `${pageWidthInPixels - 10}px`;

        const tempContainer = document.createElement('div');
        tempContainer.style.visibility = 'hidden';
        tempContainer.appendChild(contentClone);
        document.body.appendChild(tempContainer);

        this.resizeTables(contentClone, pageWidthInPixels);
        this.resizeImages(contentClone, pageWidthInPixels);
        document.body.removeChild(tempContainer);

        const fullContent = document.createElement('div');
        fullContent.appendChild(contentClone);

        await new Promise(resolve => setTimeout(resolve, 0));

        await html2pdf()
          .from(fullContent)
          .set(opt)
          .toPdf()
          .get('pdf')
          .then((pdf: any) => {
            const totalPages = pdf.internal.getNumberOfPages();

            if (totalPages > 0) {
              const lastPageIndex = totalPages - 1;
              const lastPageContent = pdf.internal.pages[lastPageIndex];
              console.log(lastPageContent);

              if (lastPageContent.length === 0) {
                pdf.deletePage(totalPages);
              } else {
                for (let i = 1; i <= totalPages; i++) {
                  pdf.setPage(i);
                  this.addWatermark(pdf, './marca-d-agua.png');
                  // Adiciona a marca d'água na página
                  this.addHeader(pdf);
                  this.addFooter(pdf, i, totalPages, this.footerHeight, pageHeight, pageWidth);
                }
              }
            }

            try {
              const pdfBlob = pdf.output('blob');
              const pdfURL = URL.createObjectURL(pdfBlob);
              const popupWindow = window.open(pdfURL, '_blank', 'width=800,height=600,scrollbars=yes');

              if (!popupWindow) {
                throw new Error('Não foi possível abrir a janela pop-up.');
              }
            } catch (error) {
              console.error('Erro ao gerar o PDF:', error);
            }
          });
      } else {
        console.error('Elemento não encontrado!');
      }

      console.log('PDF gerado com sucesso!');
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    } finally {
      // Finaliza os indicadores de carregamento
      this.loadingBar.complete();
      this.spinner.hide();
    }
  }

  resizeTables(contentClone: HTMLElement, pageWidthInPixels: number) {

    if (this.resizeTableSelectors && this.resizeTableSelectors.length > 0) {

      this.resizeTableSelectors.forEach((selector) => {
        // Ajustar tabelas que ultrapassam a largura
        const tables = contentClone.querySelectorAll(selector);
        tables.forEach((table) => {
          const tableElement = table as HTMLElement;

          // Obter largura da tabela em pixels
          const tableWidthInPixels = tableElement.offsetWidth;

          // Calcular fator de escala necessário
          if (tableWidthInPixels > pageWidthInPixels) {
            const scaleFactor = Math.min(pageWidthInPixels / tableWidthInPixels, 0.64);
            // Ajustar o tamanho da tabela proporcionalmente
            tableElement.style.transform = `scale(${scaleFactor})`;
            tableElement.style.transformOrigin = 'top left';

            let parent = table.parentElement; // Começa com o elemento pai imediato

            // Procura até encontrar a div com a classe "my-3"
            while (parent && !parent.classList.contains('my-3')) {
              parent = parent.parentElement;
            }

            // Se encontrou a div com "my-3", aplica uma altura máxima
            if (parent && parent.classList.contains('my-3')) {
              // parent.classList.remove('my-3');
              // parent.style.marginBottom = '10px';
              // Calcular a nova altura após a escala
              const scaledHeight = Math.max(tableElement.offsetHeight * scaleFactor + 33, 350);
              parent.style.height = `${scaledHeight}px`;
              parent.style.overflow = 'hidden';
            }
          }
        });
      });
    }
  }

  resizeImages(contentClone: HTMLElement, pageWidthInPixels: number) {
    // Selecionar todas as imagens no conteúdo clonado
    const images = contentClone.querySelectorAll('img');

    images.forEach((img) => {
      const imgElement = img as HTMLImageElement;

      // Obter largura da imagem em pixels
      const imageWidthInPixels = imgElement.naturalWidth;

      // Calcular fator de escala necessário
      if (imageWidthInPixels > pageWidthInPixels) {
        const scaleFactor = Math.min(pageWidthInPixels / imageWidthInPixels, 0.90);

        // Aplicar redimensionamento com escala
        imgElement.style.transform = `scale(${scaleFactor})`;
        imgElement.style.transformOrigin = 'top left';

        // Ajustar largura e altura proporcionalmente
        const scaledWidth = imgElement.offsetWidth * scaleFactor;
        const scaledHeight = imgElement.offsetHeight * scaleFactor;

        imgElement.style.width = `${scaledWidth}px`;
        imgElement.style.height = `${scaledHeight}px`;
      }
    });
  }

  addHeader(pdf: jsPDF): void {
    const margin = 5;
    const imgData = "assets/img/logo_brasao_ES.png";
    const imgWidth = 20; // largura da imagem
    const imgHeight = 20; // altura da imagem
    const imgX = margin + 20; // Margem da esquerda
    const imgY = margin; // Parte superior da página

    // Adicionar imagem
    pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);

    // Adicionar título e subtítulo
    const titleX = imgX + imgWidth + 5; // Espaço à direita da imagem
    const titleY = imgY + 10; // Centraliza o texto no eixo Y

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(14);
    pdf.setTextColor("#23507e");
    pdf.text("Governo do Estado do Espírito Santo".toUpperCase(), titleX, titleY);

    const subtitleY = titleY + 7; // Espaço entre título e subtítulo
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(12);
    pdf.text(
      "Secretaria de Estado de Controle e Transparência".toUpperCase(),
      titleX,
      subtitleY
    );
  }

  addFooter(
    pdf: jsPDF,
    pageNumber: number,
    totalPages: number,
    footerHeight: number,
    pageHeight: number,
    pageWidth: number
  ): void {
    const marginBetweenContentAndFooter = 5; // Margem entre conteúdo e linha do rodapé
    const footerStartY = pageHeight - footerHeight + marginBetweenContentAndFooter;

    // Desenhar a linha no rodapé com margem
    const lineY = footerStartY; // Posição ajustada com base na margem
    pdf.setDrawColor('#23507e'); // Cor da linha (cinza claro)
    pdf.setLineWidth(0.1); // Espessura da linha
    pdf.line(10, lineY, pageWidth - 10, lineY); // Linha horizontal (10 mm de margem)

    const textY1 = lineY + 5; // Primeira linha de texto
    pdf.setFontSize(8);
    pdf.setFont("helvetica", "normal"); // Fonte normal
    pdf.text(
      "Av. João Batista Parra, nº. 600, Ed. Aureliano Hoffman, 10º andar, Enseada do Suá, Vitória /ES - Cep: 29050-375",
      10, // Alinhado à esquerda
      textY1
    );

    const textY2 = textY1 + 5; // Segunda linha de texto
    pdf.text(
      "Tel: (27) 3636-5352",
      10, // Alinhado à esquerda
      textY2
    );

    const textY3 = textY2 + 5; // terceira linha de texto
    pdf.setFont("helvetica", "bold"); // Fonte em negrito
    pdf.text(
      "www.secont.es.gov.br",
      10, // Alinhado à esquerda
      textY3
    );

    // Obter a data/hora atual e formatá-la
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    // "Emitido por" com estilos segmentados
    const textY4 = textY2 + 5; // Linha para "Emitido por"
    const emitidoPorText = `Emitido por `;
    const userName = this.authService.usuario?.nome;
    const commaAndEm = `, em `;
    const dataHoraText = `${formattedDate}`;

    // Calcular o posicionamento alinhado à direita
    const fullTextWidth = pdf.getTextWidth(
      `${emitidoPorText}${userName}${commaAndEm}${dataHoraText}`
    );
    const textX4 = pageWidth - 10 - fullTextWidth;

    // Adicionar os segmentos do texto
    pdf.setFont("helvetica", "normal"); // "Emitido por"
    pdf.text(emitidoPorText, textX4, textY4);

    const userNameWidth = pdf.getTextWidth(emitidoPorText);
    pdf.setFont("helvetica", "bold"); // Nome do usuário
    pdf.text(userName, textX4 + userNameWidth, textY4);

    const commaAndEmWidth = pdf.getTextWidth(userName);
    pdf.setFont("helvetica", "normal"); // ", em"
    pdf.text(commaAndEm, textX4 + userNameWidth + commaAndEmWidth, textY4);

    const dataHoraWidth = pdf.getTextWidth(commaAndEm);
    pdf.setFont("helvetica", "bold"); // Data e hora
    pdf.text(dataHoraText, textX4 + userNameWidth + commaAndEmWidth + dataHoraWidth, textY4);

    // Adicionar a numeração da página logo abaixo das informações
    const pageNumberY = textY4 + 5; // Posicionar o número da página
    pdf.setFont("helvetica", "normal"); // Fonte normal
    pdf.text(
      `Página ${pageNumber}/${totalPages}`,
      pageWidth / 2, // Alinhado ao centro
      pageNumberY,
      { align: "center" }
    );
  }

  async addWatermark(pdf: jsPDF, watermarkImage: string) {
    if (this.showWatermark) {
      const opacity = 0.1;
      pdf.saveGraphicsState();
      pdf.setGState(pdf.GState({ opacity: 0.2 }));
      pdf.addImage(watermarkImage, 'PNG', 10, 10, 190, 277);
      pdf.restoreGraphicsState();
    }
  }

  cancelarClick() {
    this.cancelar.emit();
  }
}