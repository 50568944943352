<app-print-layout (cancelar)="onBack()">

	<div class="container">
		<div class="subtitulo">
			<strong>ENTENDIMENTO DA UNIDADE AUDITADA</strong><br />
			<strong>Versäo: {{ versao }} </strong>
			<br />
			<strong>Data: {{ dataFormatada }}</strong>
		</div>
		<div class="row">
			<div class="col-md-10 mx-auto header-questionario">
				<h6>Unidade a ser auditada: {{ nomeUnidadeGestora }}</h6>
			</div>
		</div>
		<div class="row">
			<div class="col-md-10 mx-auto">
				<div class="component data component-card">
					<mat-card class="mat-card">
						<mat-table [dataSource]="dataSource" class="table">
							<ng-container class="container" matColumnDef="pergunta">
								<mat-cell class="cell" *matCellDef="let element">
									<span>
										{{ element.pergunta }}
									</span>
									<br />
									<span class="resposta-pergunta" *ngIf="
										element.tipoEntrada &&
										element.tipoAvaliacaoAuditor ==
											'Concordo'
									">
										<div [innerHtml]="element.resposta"></div>
									</span>
									<span class="resposta-pergunta" *ngIf="
										element.tipoEntrada &&
										element.tipoAvaliacaoAuditor !=
											'Concordo'
									">
										<div [innerHtml]="element.avaliacaoAuditor"></div>
									</span>
								</mat-cell>
							</ng-container>

							<mat-row class="row" *matRowDef="let row; columns: displayedColumns" [ngClass]="{
								'tipo-secao': row.tipoEntrada === '',
								'tipo-pergunta': row.tipoEntrada !== ''
							}">
							</mat-row>
						</mat-table>
					</mat-card>
				</div>
			</div>
		</div>
	</div>
</app-print-layout>