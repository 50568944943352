<div class="mat-elevation-z1">
    <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear class="mat-elevation-z8"
        #tabela1="matSort" [hidden]="semDados">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container matColumnDef="avaliacao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
            <td mat-cell *matCellDef="let element">{{element.valor}}</td>
        </ng-container>
        <ng-container matColumnDef="valorDoRisco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Magnitude</th>
            <td mat-cell *matCellDef="let element">{{getMagnitudeName(element.magnitude)}}</td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="
                    let element;
                    let i = index
                " class="block text-right">
                <div class="btn-group">
                    <button [hidden]="visualizar" type="button" class="btn btn-danger btn-sm text-left"
                        (click)="excluir(element)">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="clickable" [ngClass]="'bg-light'">
        </tr>
    </table>
    <h5 [hidden]="!semDados" class="table-no-data">
        Nenhum tipo de trabalho
        cadastrado.
    </h5>
</div>