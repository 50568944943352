import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IMaginitudesEscalaRisco } from 'src/app/models/maginitudes-escala-risco.model';
import { MagnitudesEscalaRiscoService } from 'src/app/services/magnitudes-escala-risco.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-magnitude-escala',
  templateUrl: './form-magnitude-escala.component.html',
  styleUrls: ['./form-magnitude-escala.component.scss']
})
export class FormMagnitudeEscalaComponent implements OnInit {

  @Output() updateData = new EventEmitter<any>();

  model = {} as IMaginitudesEscalaRisco;
  anoSelecionado: number;
  txtBtnConcluir: string;
  campoObrigatorio = false;
  atualizando = false;
  existsItem = false;
  escalaId: number

  listData: any[] = []

  magnitudeList = [
    { id: '1', nome: 'Muito Baixa' },
    { id: '2', nome: 'Baixa' },
    { id: '3', nome: 'Media' },
    { id: '4', nome: 'Alta' },
    { id: '5', nome: 'Muito Alta' }
  ]

  constructor(private serviceMagnitude: MagnitudesEscalaRiscoService) {
    this.model.descricao = ''
  }

  setListData(e: any) {
    this.listData = e
  }

  ngOnInit(): void {
  }

  setEscalaId(escalaId: number) {
    this.escalaId = escalaId
  }

  concluir() {
    let dados = this.listData.find(o => o.magnitude == this.model.magnitude)

    if (dados) {
      this.existsItem = true;
      this.model.descricao = ''
      this.model.magnitude = null
      this.model.valor = 0
      return
    }

    if (this.model.magnitude == '0' || !this.model.magnitude) {
      Swal.fire({ title: "Erro", text: 'Magnitude não informada', icon: "error" });
      return
    }

    if (this.model.valor == 0 || !this.model.valor) {
      Swal.fire({ title: "Erro", text: 'Valor não informado', icon: "error" });
      return
    }

    this.model.escalasId = this.escalaId
    this.serviceMagnitude.inserir(this.model).then((res) => {
      this.updateData.emit(this.escalaId)
    })
  }

}
