import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IAmostraModel } from 'src/app/models/amostra.model';
import { AmostraService } from 'src/app/services/amostra.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../base-form/base-form.component';
import { InserirAmostraComponent } from './inserir-amostra/inserir-amostra.component';

@Component({
  selector: 'app-aba-amostra',
  templateUrl: './aba-amostra.component.html',
  styleUrls: ['./aba-amostra.component.scss']
})
export class AbaAmostraComponent extends BaseFormComponent implements OnInit {
  public displayedColumns: string[] = ['nome', 'descricao', 'ug', 'arquivos', 'acoes'];
  public dataSource = new MatTableDataSource<IAmostraModel>([]);
  public semDados = true;
  public amostra: IAmostraModel = {} as IAmostraModel;
  public tipoAmostra!: string;
  @Input() public visualizar: boolean;
  @Input() public hiddenEdicao: boolean;
  @Input() public planejamentoId: number;
  @Input() public numeroOpa: string;
  @Input() public statusPlanejamento: number;
  

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('tabela1') public tabela1: MatSort;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    public matDialog: MatDialog,
    private amostraService: AmostraService,
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.router.url.includes("planejamento")) {
      this.tipoAmostra = 'planejamento'
      this.planejamentoId = this.id;
    }
    if (this.router.url.includes("osa")) {
      this.tipoAmostra = 'osa'
    }
    if (this.router.url.includes("auditoria")) {
      this.tipoAmostra = 'auditoria'
    }
  }


  ngOnInit(): void {
    this.visualizar = this.route.snapshot.routeConfig.path.includes('visualizar') || this.route.snapshot.routeConfig.path.includes('osa');
    this.obterAmostra(this.tipoAmostra)
  }

  public obterAmostra(tipoAmostra: string) {
    this.amostraService.obterAmostra(this.id, tipoAmostra)
      .then(res => {
        if (res.sucesso) {
          this.dataSource = new MatTableDataSource<IAmostraModel>(res.dados);

          this.dataSource._updateChangeSubscription();

          this.dataSource.sortingDataAccessor = (item: IAmostraModel, property) => {
            switch (property) {
              case 'nome':
                return item?.nome;
                case 'descricao':
                  return item?.descricao;
                  case 'arquivos':
                    return item.amostraArquivos[0].nome;
                    case 'ug':
                      return item?.amostraUnidadeGestoras[0].unidadeGestora.sigla;
                      default:
                        return item[property];
                      }
                    };

                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.tabela1;
                    this.semDados = this.dataSource.filteredData.length === 0;
                  } else {
                    Swal.fire({
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: 'warning',
            title: 'Atenção',
            text: res.mensagem.descricao,
          });
        }
      }).catch(err => {
        this.exibirMensagemAlerta(err.mensagem.descricao);
      })
  }

  public Download(id, nome) {
    this.amostraService.download(id, this.tipoAmostra).then((res) =>{
      if (res.sucesso) {
        const link = document.createElement("a");
        link.href = res.dados.toString();
        link.download = nome;
        link.click();
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }})
	}

  public obterAmostraPorId(amostra: IAmostraModel, tipoAmostra: string) {
    this.amostraService.obterAmostraId(this.id, amostra.id, tipoAmostra).then(res => {
      if (res.sucesso) {
        this.amostra = res.dados
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    }).catch(err => {
      this.exibirMensagemAlerta(err.mensagem.descricao);
    })
  }

  public exibirModalAdicionarAmostra(dados?: IAmostraModel, visualizar?: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '45vw';
    dialogConfig.maxHeight = '85vh';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = false;

    dialogConfig.data = {
      id: this.planejamentoId,
      tipoAmostra: this.tipoAmostra,
      visualizar,
      numeroOpa: this.numeroOpa,
      dados
    }

    const modal = this.matDialog.open(InserirAmostraComponent, dialogConfig);
    modal.afterClosed().subscribe(data => {
      this.obterAmostra(this.tipoAmostra);
    });
  }

  public visualizarModalAdicionarAmostra(dados?: IAmostraModel) {
    this.exibirModalAdicionarAmostra(dados, true);
  }

  public imprimir(element: IAmostraModel) {
    this.router.navigate([`${this.tipoAmostra}/${this.id}/planejar/amostra/${element.id}/imprimir`], {state: {planejamentoId: this.id, numeroOpa: this.numeroOpa, statusPlanejamento: this.statusPlanejamento}})
  }

  public excluirAmostra(amostra: IAmostraModel) {
    Swal.fire({
      title: 'Excluir Amostra',
      text: 'Tem certeza que deseja excluir essa Amostra?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    })
      .then((res) => {
        if (res.value) {
          this.amostraService.excluirAmostra(this.id, amostra.id, this.tipoAmostra)
            .then((res) => {
              if (res.sucesso) {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  text: 'Amostra excluída com sucesso.',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                });
              } else {
                this.exibirMensagemAlerta(res.mensagem.descricao);
              }
            })
            .catch((err) => {
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
            })
            .finally(() => {
              this.obterAmostra(this.tipoAmostra)
            });
        }
      })
  }
}
