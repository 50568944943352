<div class="mat-elevation-z1">
    <mat-toolbar class="table-header">
        <mat-toolbar-row>
            <label class="mb-1" style="font-size: 16px">Níveis de Risco Residual (RR)</label>
            <span class="fill-remaining-space"></span>
            <button type="button" class="btn btn-success btn-sm mr-1" (click)="abrirNiveisDeRisco()">
                <i class="far fa-add"></i>
                Adicionar
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear class="mat-elevation-z8"
        #tabela1="matSort" [hidden]="semDados">
        <tr mat-header-row *matHeaderRowDef="displayedColumnsNiveisRisco"></tr>
        <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Classificação</th>
            <td mat-cell *matCellDef="let element">{{element.nome}}</td>
        </ng-container>
        <ng-container matColumnDef="operador_1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor RR</th>
            <td mat-cell *matCellDef="let element">{{getRiscoControle(element)}}</td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="
                    let element;
                    let i = index
                " class="block text-right">
                <div class="btn-group">
                    <button [hidden]="visualizar" type="button" class="btn btn-primary btn-sm mr-1"
                        (click)="abrirNiveisDeRisco(element)">
                        <i class="fas fa-pen"></i>
                    </button>
                    <button [hidden]="visualizar" type="button" class="btn btn-danger btn-sm text-left"
                        (click)="excluir(element)">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsNiveisRisco" class="clickable" [ngClass]="'bg-light'">
        </tr>
    </table>
    <h5 [hidden]="!semDados" class="table-no-data">
        Nenhum tipo de trabalho
        cadastrado.
    </h5>
</div>
<div style="display: flex; background-color: #f1f1f1; margin-top: 10px; align-items: center;">
    <h6 style="padding-top: 10px; padding-left: 10px; font-weight: bold;">Fórmula</h6>
    <button [hidden]="visualizar" type="button" class="btn btn-primary btn-sm mr-1" (click)="abrirFormulaRisco()" style="margin-left: 10px;">
        <i class="fas fa-pen"></i>
    </button>
    <h6 style="padding-top: 10px; padding-left: 10px; font-weight: bold;">{{this.formulaRiscoResidual?.campo_1}} {{this.formulaRiscoResidual?.operador}} {{this.formulaRiscoResidual?.campo_2}}</h6>
</div>