import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IEscalas } from 'src/app/models/escalas.model';
import { FiltroModel } from 'src/app/models/filtro.model';
import { INiveisRiscoInerente } from 'src/app/models/niveis-risco-inerente.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { EscalasService } from 'src/app/services/escalas.service';
import { NiveisRiscoInerenteService } from 'src/app/services/niveis-risco-inerente.service';
import { ModalEscalasComponent } from './modal-escalas/modal-escalas.component';
import Swal from 'sweetalert2';
import { ModalRiscoInerenteComponent } from './modal-risco-inerente/modal-risco-inerente.component';
import { ModalFormulaRiscoInerenteComponent } from './modal-formula-risco-inerente/modal-formula-risco-inerente.component';
import { FormulaRiscoInerenteService } from 'src/app/services/formula-risco-inerente.service';

@Component({
  selector: 'app-criterios-avaliacao-form-risco-inerente',
  templateUrl: './criterios-avaliacao-form-risco-inerente.component.html',
  styleUrls: ['./criterios-avaliacao-form-risco-inerente.component.scss']
})
export class CriteriosAvaliacaoFormRiscoInerenteComponent implements OnInit {

  criteriosAvaliacaoRiscosId: any;
  formulaRiscoInerente: any;
  visualizar = false;
  escalasDataSource = new MatTableDataSource<IEscalas>([]);
  niveisRiscoDataSource = new MatTableDataSource<INiveisRiscoInerente>([]);
  selectedModel: IEscalas;
  displayedEscalasColumns: string[] = [
    "nome",
    "actions",
  ];

  displayedColumnsNiveisRisco: string[] = [
    "nome",
    "operador_1",
    "actions"
  ];

  searchNome: string;
  searchAtivo = true;
  semDadosEscalas = true;
  semDadosNiveisRisco = true;
  permissoes: IPermissaoModel;
  filtro = {} as FiltroModel;
  totalItens: number;
  pageEvent: any;

  constructor(
    private escalasService: EscalasService,
    public matDialog: MatDialog,
    private formulaRiscoService: FormulaRiscoInerenteService,
    private niveisRiscoService: NiveisRiscoInerenteService
  ) { }

  setCriteriosAvaliacaoRiscosId(criteriosAvaliacaoRiscosId: number) {
    this.criteriosAvaliacaoRiscosId = criteriosAvaliacaoRiscosId
    this.getEscalasByCriterios();
    this.getNiveisRiscoByCriterios();
    this.getFormulaRiscoInerente();
  }

  ngOnInit(): void { }

  salvarDados(e: any) { }

  editarEscalas(e: any) {
    this.abrirModalEscalas(e)
  }

  editarNivelInerente(e: any) {
    this.abrirNiveisDeRisco(e)
  }

  excluirRiscoInerente(e: any) {
    Swal.fire({
      title: "Excluir Risco Inerente",
      text: "Tem certeza que deseja excluir esse Risco?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        this.niveisRiscoService.excluir(e.id).then(() => {
          this.getNiveisRiscoByCriterios()
        })
        return;
      }
      return;
    });
  }

  excluirEscalas(e: any) {
    Swal.fire({
      title: "Excluir Escalas",
      text: "Tem certeza que deseja excluir essa escala?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        this.escalasService.excluir(e.id).then(() => {
          this.getEscalasByCriterios()
        })
        return;
      }
      return;
    });
  }

  abrirModalEscalas(element?: IEscalas) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-component";
    dialogConfig.width = "650px";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: element ? element.id : 0,
      nome: element?.nome,
      ativo: element?.ativo,
      criteriosAvaliacaoRiscosId: this.criteriosAvaliacaoRiscosId
    };

    const modal = this.matDialog.open(
      ModalEscalasComponent,
      dialogConfig
    );
    modal.afterClosed().subscribe((sucesso) => {
      if (sucesso) {
        this.getEscalasByCriterios();
      }
    });
  }


  abrirNiveisDeRisco(element?: INiveisRiscoInerente) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-component";
    dialogConfig.width = "1000px";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: element ? element.id : 0,
      nome: element?.nome,
      operador: element?.operador,
      valor: element?.valor,
      operador_1: element?.operador_1,
      valor_1: element?.valor_1,
      ativo: element?.ativo,
      criteriosAvaliacaoRiscosId: this.criteriosAvaliacaoRiscosId
    };

    const modal = this.matDialog.open(
      ModalRiscoInerenteComponent,
      dialogConfig
    );

    modal.afterClosed().subscribe((sucesso) => {
      if (sucesso) {
        this.getNiveisRiscoByCriterios();
      }
    });
  }


  getEscalasByCriterios() {
    this.escalasService.obter(this.criteriosAvaliacaoRiscosId, true, true).then((res) => {
      this.escalasDataSource = new MatTableDataSource(res.dados);
      this.escalasDataSource._updateChangeSubscription();
      this.semDadosEscalas = this.escalasDataSource.filteredData.length === 0;
    })
  }

  getNiveisRiscoByCriterios() {
    this.niveisRiscoService.obter(this.criteriosAvaliacaoRiscosId, true, true).then((res) => {
      this.niveisRiscoDataSource = new MatTableDataSource(res.dados);
      this.niveisRiscoDataSource._updateChangeSubscription();
      this.semDadosNiveisRisco = this.niveisRiscoDataSource.filteredData.length === 0;
    })
  }

  getRiscoControle(e: any) {
    let retorno = ""
    if (e.valor_1) {
      retorno = `${e.operador} ${e.valor} e ${e.operador_1} ${e.valor_1}`
    } else if (e.valor) {
      retorno = `${e.operador} ${e.valor}`
    }
    return retorno
  }

  abrirFormulaRisco() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-component";
    dialogConfig.width = "1000px";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: this.formulaRiscoInerente ? this.formulaRiscoInerente.id : 0,
      campo_1: this.formulaRiscoInerente?.campo_1,
      operador_1: this.formulaRiscoInerente?.operador_1,
      campo_2: this.formulaRiscoInerente?.campo_2,
      operador_2: this.formulaRiscoInerente?.operador_2,
      campo_3: this.formulaRiscoInerente?.campo_3,
      criteriosAvaliacaoRiscosId: this.formulaRiscoInerente?.criteriosAvaliacaoRiscosId,
      ativo: this.formulaRiscoInerente?.ativo
    };

    const modal = this.matDialog.open(
      ModalFormulaRiscoInerenteComponent,
      dialogConfig
    );

    modal.afterClosed().subscribe((sucesso) => {
      if (sucesso) {
        this.getNiveisRiscoByCriterios();
        this.getFormulaRiscoInerente()
      }
    });
  }

  getFormulaRiscoInerente() {
    this.formulaRiscoService.obter(this.criteriosAvaliacaoRiscosId, true, true).then((res) => {
      this.formulaRiscoInerente = res?.dados[0]
    })
  }
}
