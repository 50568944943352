<table *ngIf="habilitado" class="page-print">
    <thead class="header-print">
        <tr>
            <th id="th-logo" scope="col">
                <img src="assets/img/logo_brasao_ES.png" alt="Brasão ES" class="brand-image" />
            </th>
            <th id="th-title" scope="col">
                <div class="title">
                    <h3><strong>Governo do Estado do Espírito Santo</strong></h3>
                    <h5>Secretaria de Estado de Controle e Transparência</h5>
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td colspan="2">
                <div class="content-print">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </div>
            </td>
        </tr>
    </tbody>
    <tfoot class="footer-print">
        <tr>
            <td colspan="2">Av. João Batista Parra, nº. 600, Ed. Aureliano Hoffman, 10º andar, Enseada do Suá, Vitória
                /ES - Cep: 29050-375</td>
        </tr>
        <tr>
            <td colspan="2">Tel: (27) 3636-5352</td>
        </tr>
        <tr>
            <td colspan="1"><b>www.secont.es.gov.br</b></td>
            <td colspan="1" class="text-right">Emitido por <b>{{this.authService.usuario?.nome}}</b>, em <b>{{dataAtual}}</b></td>
        </tr>
    </tfoot>
</table>
<div class="barra-botoes no-print">
    <div class="barra-botoes-interno justify-content-center">
        <div class="row">
            <div class="col-md-12">
                <button type="button" (click)="print()" class="btn btn-primary btn-sm mr-1 no-print">
                    <i class="fas fa-print"></i> Imprimir
                </button>
                <button type="button" (click)="cancelarClick()" class="btn btn-default btn-sm no-print">
                    <i class="fas fa-times"></i> Fechar
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!habilitado">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>