<div class="table-container p-2">
    <div class="row">
        <div class="form-group col-md-4 was-validated" [ngClass]="{ 'was-validated': campoObrigatorio === true }">
            <label for="inputNome" class="mb-1">Valor</label>
            <input required class="form-control form-control-sm" type="text" id="inputNome" [(ngModel)]="model.valor" (ngModelChange)="existsItem = false" />
            <div class="invalid-feedback">
                Campo obrigatório
            </div>
        </div>
        <div class="form-group col-md-8 was-validated" [ngClass]="{ 'was-validated': campoObrigatorio === true }">
            <label for="inputOperador_1" class="mb-1">Magnitude</label>
            <select id="inputOperador_1" #selectTipoTrabalho [(ngModel)]="model.magnitude" (ngModelChange)="existsItem = false"
                class="form-control form-control-sm" required>
                <option *ngFor="let item of magnitudeList" [ngValue]="item.id">
                    {{ item.nome }}
                </option>
            </select>
            <div class="invalid-feedback">
                Campo obrigatório
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-12 was-validated" [ngClass]="{ 'was-validated': campoObrigatorio === true }">
            <label for="inputNome" class="mb-1">Descrição</label>
            <textarea required class="form-control form-control-sm" type="text" id="inputNome" rows="3"
                [(ngModel)]="model.descricao">
                </textarea>
            <div class="invalid-feedback">
                Campo obrigatório
            </div>
        </div>
    </div>
    <div style="display: flex; justify-content: space-between;">
        <div>
            <span *ngIf="existsItem" style="color: red">Valor já inserido! Insera outro valor</span>
        </div>
        <div>
            <button type="button" class="btn btn-success btn-sm mr-1" (click)="concluir()">
                <i class="far fa-add"></i>
                Adicionar
            </button>
        </div>
    </div>
</div>