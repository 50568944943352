<form [formGroup]="formCustoRecurso" #f="ngForm">
  <div class="card-body">

    <div class="row">
      <div class="form-group col-md-3">
        <label for="nome" class="mb-1">Custos e Recursos</label>
        <input [readonly]="visualizar" type="text" id="nome" class="form-control form-control-sm" formControlName="nome"
          [(ngModel)]="modelCustoRecursoPlanejamento.nome" maxlength="100" />
      </div>
      <div class="form-group col-md-3">
        <label for="valorUnitario" class="mb-1 ">Valor</label>
        <input [readonly]="visualizar" type="text" min="0" maxlength="10"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="valor" class="form-control form-control-sm text-right"
          [(ngModel)]="modelCustoRecursoPlanejamento.valorUnitario" formControlName="valorUnitario"
          (keyup)="modelCustoRecursoPlanejamento.valorUnitario = formatarInput($event.target.value)" />
      </div>
      <div class="form-group col-md-3">
        <label for="quantidade" class="mb-1 ">Quantidade</label>
        <input type="text" min="0" maxlength="5" [readonly]="visualizar"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="quantidade" class="form-control form-control-sm text-right"
          [(ngModel)]="modelCustoRecursoPlanejamento.quantidade" formControlName="quantidade" />
      </div>
      <div class="form-group col-md-3">
        <label for="quantidade"></label>
        <div class="input-group input-group-sm" style="width: 100%;">
          <div class=" input-group-append mt-1">
            <button type="button" class="btn btn-success" type="submit" (click)="incluirCustoRecurso()"
              [disabled]="!(formCustoRecurso.value.nome && formCustoRecurso.value.quantidade && formCustoRecurso.value.valorUnitario) || visualizar">
              <i class="fas fa-plus"></i>
            </button>
            <button type="button" class="btn btn-info" (click)="exibirModalCustoRecurso()" [disabled]="visualizar || hiddenEdicao">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="row">
  <div class="col-md-12">
    <div class="mat-elevation-z1">
      <table mat-table [dataSource]="custoRecursoPlanejamentoDataSource">
        <tr mat-header-row
          *matHeaderRowDef="['nome', 'valorUnitario', 'quantidade', 'valorTotal', 'actions']"
          class="bg-light"></tr>

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef>Item </th>
          <td mat-cell *matCellDef="let element"> {{ element.nome }}</td>
        </ng-container>


        <ng-container matColumnDef="valorUnitario">
          <th mat-header-cell *matHeaderCellDef>Valor Unitário </th>
          <td mat-cell *matCellDef="let element"> {{ formatarInput(element.valorUnitario) }}</td>
        </ng-container>


        <ng-container matColumnDef="quantidade">
          <th mat-header-cell *matHeaderCellDef>Quantidade </th>
          <td mat-cell *matCellDef="let element"> {{ element.quantidade }}</td>
        </ng-container>


        <ng-container matColumnDef="valorTotal">
          <th mat-header-cell *matHeaderCellDef>Valor Total </th>
          <td mat-cell *matCellDef="let element"> {{ formatarInput(element.valorUnitario * element.quantidade) }}</td>
        </ng-container>

        <ng-container cdkColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = index" class="block">
            <div class="btn-group">
              <button type="button" class="btn btn-primary btn-xs text-left" *ngIf="!visualizar"
                (click)="editarCustoRecurso(element)">
                <i class="far fas fa-pen"></i></button>
              <button type="button" class="btn btn-danger btn-xs text-left" *ngIf="!visualizar"
                (click)="excluirCustoRecurso(element.id)">
                <i class="far fa-trash-alt"></i></button>
            </div>
          </td>
        </ng-container>

        <tr mat-row
          *matRowDef="let row; columns: ['nome','valorUnitario', 'quantidade', 'valorTotal', 'actions'];">
        </tr>
      </table>
      <h5 [hidden]="!custoRecursoPlanejamentoSemDados" class="table-no-data">Nenhum custo recurso</h5>
    </div>
  </div>
</div>
