import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICriteriosAvaliacaoRiscos } from 'src/app/models/criterios-avaliacao-riscos.model';
import { CriteriosAvaliacaoRiscoService } from 'src/app/services/criterios-avaliacao-risco.service';

@Component({
  selector: 'app-criterios-avaliacao-form-dados',
  templateUrl: './criterios-avaliacao-form-dados.component.html',
  styleUrls: ['./criterios-avaliacao-form-dados.component.scss']
})
export class CriteriosAvaliacaoFormDadosComponent implements OnInit {

  id: number = 0
  form = new FormGroup({
    id: new FormControl({ value: '0', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required)
  });

  visualizar = false

  constructor(
    public route: ActivatedRoute,
    private service: CriteriosAvaliacaoRiscoService
  ) { }

  ngOnInit(): void {

  }

  getDataById(e: any) {
    this.service.obterPorId(e).then((r) => {
      this.form.patchValue(r.dados)
    })

  }

  salvarDados(e: any) {
    let dados = this.form.getRawValue() as ICriteriosAvaliacaoRiscos
    if (dados?.id == 0) {
      this.service.inserir(dados)
    } else {
      this.service.atualizar(dados)
    }
  }

}
