import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { INiveisRiscoInerente } from 'src/app/models/niveis-risco-inerente.model';
import { NiveisRiscoInerenteService } from 'src/app/services/niveis-risco-inerente.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-modal-risco-inerente',
  templateUrl: './modal-risco-inerente.component.html',
  styleUrls: ['./modal-risco-inerente.component.scss']
})
export class ModalRiscoInerenteComponent extends BaseFormComponent implements AfterViewInit, OnInit {

  model = {} as INiveisRiscoInerente;
  anoSelecionado: number;
  txtBtnConcluir: string;
  campoObrigatorio = false;
  atualizando = false;

  textoModal = 'Adicionar Nível de Risco'

  operadorList: any[] = [
    { id: '>=', nome: 'Maior ou Igual (>=)' },
    { id: '<=', nome: 'Menor ou Igual (<=)' },
    { id: '>', nome: 'Maior (>)' },
    { id: '<', nome: 'Menor (<)' }
  ];

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalRiscoInerenteComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private serviceRiscoInerente: NiveisRiscoInerenteService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);
    this.model.id = data.id;
    this.model.nome = data?.nome;
    this.model.operador = data?.operador;
    this.model.valor = data?.valor;
    this.model.operador_1 = data?.operador_1;
    this.model.valor_1 = data?.valor_1;
    this.model.ativo = data?.ativo;
    this.model.criteriosAvaliacaoRiscosId = data?.criteriosAvaliacaoRiscosId;

    if (!this.model.id || this.model.id == 0) {
      this.atualizando = false;
      this.txtBtnConcluir = "Inserir"
    }
    else {
      this.atualizando = true;
      this.txtBtnConcluir = "Atualizar"
      this.textoModal = 'Editar Nível de Risco'
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  concluir() {
    if (this.atualizando) {
      this.serviceRiscoInerente.atualizar(this.model).then((res) => { this.dialogRef.close(true); })
    } else {
      this.serviceRiscoInerente.inserir(this.model).then((res) => { this.dialogRef.close(true); })
    }
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
