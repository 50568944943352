<div id="modal-content-a-equipe">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">Escalas</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancelar()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">
                <div class="row">
                    <div class="form-group col-md-12 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="inputAvaliacao" class="mb-1">Avaliação</label>
                        <select id="selectTipoTrabalho" #selectTipoTrabalho [(ngModel)]="model.avaliacao" (ngModelChange)="setValues()"
                            class="form-control form-control-sm" required>
                            <option *ngFor="let item of avaliacaoList" [ngValue]="item.nome">
                                {{ item.nome }}
                            </option>
                        </select>
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="inputValorDoRisco" class="mb-1">Valor do Risco</label>
                        <input required class="form-control form-control-sm" type="text" id="inputValorDoRisco" [attr.disabled]="true"
                            [(ngModel)]="model.valorDoRisco" />
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="inputSituacaoControle" class="mb-1">Situação do Controle Existente</label>
                        <input required class="form-control form-control-sm" type="text" id="inputSituacaoControle"
                            [(ngModel)]="model.situacaoControle" />
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="inputNivelDaConfianca" class="mb-1">Nível de Confiança</label>
                        <input required class="form-control form-control-sm" type="text" id="inputNivelDaConfianca" [attr.disabled]="true"
                            [(ngModel)]="model.nivelDaConfianca" />
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
                    <i class="fas fa-check"></i> {{txtBtnConcluir}}
                </button>
                <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                    <i class="fas fa-times"></i> Cancelar
                </button>
            </div>
        </div>
    </div>
</div>