import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { IBaseModel } from '../models/base.model';
import { IEscalas } from '../models/escalas.model';


@Injectable({
  providedIn: 'root'
})
export class EscalasService extends BaseService {


  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(criteriosAvaliacaoRiscosId: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IEscalas[]>> {
    let params = new HttpParams();
    params = params.append('criteriosAvaliacaoRiscosId', criteriosAvaliacaoRiscosId || '0');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IEscalas[]>>(`${this.apiBaseUrl}/escalas`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IEscalas>> {
    return this.httpClient
      .get<IBaseModel<IEscalas>>(`${this.apiBaseUrl}/escalas/${id}`)
      .toPromise();
  }

  public async inserir(data: IEscalas): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/escalas`, data)
      .toPromise();
  }

  public async atualizar(data: IEscalas): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/escalas/${data.id}`, data)
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/escalas/${id}/excluir`, {})
      .toPromise();
  }
}