<div class="header">
    <img src="assets/img/logo_brasao_ES.png" alt="SECONT-SIAC" class="brand-image" />
    <div class="title">
        <h4><strong>Governo do Estado do Espírito Santo</strong></h4>
        <h5>Secretaria de Estado de Controle e Transparência</h5>
    </div>
</div>
<div class="content-pdf-export">
    <ng-content></ng-content>
    <img id="marca-d-agua-minuta" class="watermark" src="marca-d-agua.png" />
    <img id="marca-d-agua-rascunho" class="watermark" src="marca-d-agua-rascunho.png" />
</div>
<div class="barra-botoes">
    <div class="barra-botoes-interno justify-content-center">
        <div class="row">
            <div class="col-md-12">
                <button type="button" (click)="generatePDF()" class="btn btn-primary btn-sm mr-1 no-print">
                    <i class="fas fa-print"></i> Imprimir
                </button>
                <button type="button" (click)="cancelarClick()" class="btn btn-default btn-sm no-print">
                    <i class="fas fa-times"></i> Fechar
                </button>
            </div>
        </div>
    </div>
</div>