import { toFormatValue, toRemoveCharacters } from 'src/app/shared/util/toFormatValue';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { IBaseModel } from 'src/app/models/base.model';
import { ICustoRecursoPlanejamentoModel } from 'src/app/models/custo-recurso-planejamento.model';
import { CustoRecursoPlanejamentoService } from 'src/app/services/custo-recurso-planejamento.service';
import { CustoRecursoService } from 'src/app/services/custo-recurso.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../base-form/base-form.component';
import { ModalCustoRecursoPlanejamentoComponent } from './modal-custo-recurso-planejamento/modal-custo-recurso-planejamento.component';

@Component({
  selector: 'app-custos-recursos',
  templateUrl: './custos-recursos.component.html',
  styleUrls: ['./custos-recursos.component.scss']
})
export class CustosRecursosComponent extends BaseFormComponent implements OnInit {
  public modelCustoRecursoPlanejamento: ICustoRecursoPlanejamentoModel = {} as ICustoRecursoPlanejamentoModel;
  public custoRecursoColunas = ['selecao', 'nome', 'valorUnitario', 'quantidade', 'valorTotal'];
  public custoRecursoPlanejamentoDataSource = new MatTableDataSource<ICustoRecursoPlanejamentoModel>([]);
  public custoRecursoPlanejamentoSemDados = true;
  public searchAtivo = true;
  public submit: boolean;
  public tipoTrabalho: string;
  @Input() visualizar: boolean;
  @Input() hiddenEdicao: boolean;

  public formCustoRecurso = new FormGroup({
    nome: new FormControl('', Validators.required),
    valorUnitario: new FormControl('', Validators.required),
    quantidade: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private custoRecursoService: CustoRecursoService,
    private custoRecursoPlanejamentoService: CustoRecursoPlanejamentoService,
    public matDialog: MatDialog,
    private dateHelper: DateHelper,
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.router.url.includes("planejamento")) {
      this.tipoTrabalho = 'planejamento';
    }
    if (this.router.url.includes("osa")) {
      this.tipoTrabalho = 'osa';
    }
  }

  ngOnInit(): void {
    this.obterCustoRecurso()
  }

  public async obterCustoRecurso() {
    try {
      this.custoRecursoPlanejamentoService.obterPorId(this.id, this.tipoTrabalho).then((res) => {
        if (res.sucesso) {
          this.custoRecursoPlanejamentoDataSource = new MatTableDataSource<ICustoRecursoPlanejamentoModel>
          (
            res.dados
          );
          this.custoRecursoPlanejamentoDataSource._updateChangeSubscription();
          this.modelCustoRecursoPlanejamento = {} as ICustoRecursoPlanejamentoModel;
          this.custoRecursoPlanejamentoSemDados = res.dados.length != 0 ? false : true;
        } else {
          this.submit = false;
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.router.navigate(['/planejamento']);
          return;
        }
      });
    } catch (err) {
      this.submit = false;
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem?.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.router.navigate(['/planejamento']);
    }
  }

  public async exibirModalCustoRecurso() {
    try {
      const res = await this.custoRecursoService.obter('', true);
      if (res.sucesso) {
        const dadosFiltrados = res.dados.filter(constatacao => constatacao.ativo);
        const modal = this.exibirModal('Selecionar custo e recurso ', dadosFiltrados);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.modelCustoRecursoPlanejamento.id = data.id;
            this.modelCustoRecursoPlanejamento.nome = data.nome;
            this.modelCustoRecursoPlanejamento.valorUnitario = data.valorMedio;
          }
        }
        );
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async incluirCustoRecurso() {

    try {
      let res: IBaseModel<void> = null;
      this.formCustoRecurso.value.planejamentoId = this.id;
      const dados = {
        nome: this.modelCustoRecursoPlanejamento.nome,
        planejamentoId: this.id,
        osaId: this.id,
        valorUnitario: toRemoveCharacters(this.modelCustoRecursoPlanejamento.valorUnitario),
        quantidade: Number(this.modelCustoRecursoPlanejamento.quantidade)
      } as ICustoRecursoPlanejamentoModel
      res = await this.custoRecursoPlanejamentoService.inserir(dados, this.tipoTrabalho);

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Registro salvo com sucesso!',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.obterCustoRecurso();

      } else {
        const validacoes = this.montarMensagensValidacao(res);

        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: validacoes,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }

    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async editarCustoRecurso(model: ICustoRecursoPlanejamentoModel) {
    try {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = model;
      const modal = this.matDialog.open(
        ModalCustoRecursoPlanejamentoComponent,
        dialogConfig
      );
      modal.afterClosed().subscribe((data) => {
        if (data) {
          data.planejamentoId = this.id;
          const dados = {
            id: data.id,
            nome: data.nome,
            planejamentoId: this.id,
            osaId: this.id,
            valorUnitario: Number(toRemoveCharacters(data.valorUnitario)),
            quantidade: data.quantidade
          } as ICustoRecursoPlanejamentoModel
          this.custoRecursoPlanejamentoService.atualizar(dados, this.tipoTrabalho)
          .then((res) => {
            if (res.sucesso) {
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'success',
                text: 'Registro atualizado com sucesso!',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
              this.obterCustoRecurso();
            }else{
              Swal.fire({
             toast: true,
             position: 'top-end',
             icon: 'warning',
             text: res.mensagem.descricao,
             showConfirmButton: false,
             timer: 5000,
             timerProgressBar: true,
           });
           }
          })
        }
      });
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }


  public excluirCustoRecurso(ind: number) {
    Swal.fire({
      title: "Excluir Registro",
      text: "Tem certeza que deseja excluir esse Registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((res) => {
      if (res.value) {
        this.custoRecursoPlanejamentoService
        .excluir(ind, this.tipoTrabalho)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: 'Registro excluído com sucesso!',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            this.obterCustoRecurso();
          } else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'warning',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
      }})

  }

  formatarInput(value) {
    return toFormatValue(value);
  }
}
