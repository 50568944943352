<app-print-layout (cancelar)="cancelar()">
  <div class="content-header no-print">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']">
                <i class="fas fa-home"></i>
                Página Inicial</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/rolresponsaveis']">Rol de Responsáveis</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-outline card-lightblue">
            <div class="card-header doNotPrint">
              <h3 class="card-title mb-0">
                Imprimir Rol de Responsáveis por Unidade Gestora
              </h3>

              <div class="card-body p-2">
                <div class="mat-elevation-z4">
                  <mat-toolbar class="table-header">
                    <span class="fill-remaining-space"></span>
                    <span class="fill-remaining-space"></span>
                    <div class="input-group input-group-sm mr-1" style="width: 100px">
                      <input type="datetime" name="date_search" class="form-control float-right"
                        placeholder="Data Início" [(ngModel)]="dataInicio" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" />
                    </div>

                    <div class="input-group input-group-sm mr-1" style="width: 100px">
                      <input type="datetime" name="date_search" class="form-control float-right" placeholder="Data Fim"
                        [(ngModel)]="dataFim" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" />
                    </div>
                    <div class="input-group input-group-sm" style="width: 200px">
                      <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscar()"
                        placeholder="Pesquisar" [(ngModel)]="searchNome" />

                      <div class="input-group-append">
                        <button type="button" class="btn btn-info" (click)="buscar()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                    <div class="btn-group">
                      <button type="button" data-toggle="dropdown" class="btn btn-tool btn-default dropdown-toggle">
                        <i class="fas fa-filter"></i>
                      </button>
                      <div role="menu" class="dropdown-menu dropdown-menu-right
                    dropdown-menu-checkbox">
                        <a (click)="searchAtivo= undefined; buscar()" class="dropdown-item"><i [ngClass]="searchAtivo === undefined
                        ? 'fas fa-check-circle'
                        : 'far fa-circle'"></i>Exibir todos</a>
                        <a (click)="searchAtivo= true; buscar()" class="dropdown-item"><i [ngClass]="searchAtivo === true
                        ? 'fas fa-check-circle'
                        : 'far fa-circle'"></i>Exibir somente ativos</a>
                        <a (click)="searchAtivo= false; buscar()" class="dropdown-item"><i [ngClass]="searchAtivo === false
                        ? 'fas fa-check-circle'
                        : 'far fa-circle'"></i>Exibir somente inativos</a>
                      </div>
                    </div>
                  </mat-toolbar>
                </div>
              </div>


            </div>
            <div class="card-body p-2">
              <h4 class="info-user">
                Emitido pelo usuário
                <strong>{{ authService.usuario?.nome }}</strong> na data
                <strong>{{ dataHoje | date: "dd/MM/yyyy HH:mm:ss" }}</strong>
              </h4>
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title mb-0">Responsáveis por UG</h3>
                </div>
                <div class="card-body p-2" *ngFor="let ug of unidadesGestoras">
                  <table class="table">
                    <thead>
                      <th>Nº da UG</th>
                      <th>Unidade Gestora</th>
                    </thead>
                    <tbody>
                      <td>
                        {{ ug.codigoUg }}
                      </td>
                      <td>
                        {{ ug.sigla }} - {{ ug.nome }}
                      </td>
                    </tbody>
                  </table>
                  <div class="mat-elevation-z4">
                    <table class="table table-bordered w-100">
                      <thead>
                        <th>Nome do Responsável</th>
                        <th>Nome da Responsabilidade</th>
                        <th>Tipo do Agente</th>
                        <th>Data Início</th>
                        <th>Data Fim</th>
                        <th>Situação</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let responsavel of ug.responsaveis">
                          <td>
                            {{ responsavel.nome }}
                          </td>
                          <td>
                            {{ responsavel.naturezaResponsabilidade?.nome }}
                          </td>
                          <td>
                            {{ responsavel.tipoAgente?.descricao }}
                          </td>
                          <td>
                            {{ responsavel.dataInicio | date: "dd/MM/yyyy" }}
                          </td>
                          <td>
                            {{ responsavel.dataFim | date: "dd/MM/yyyy" }}
                          </td>
                          <td>
                            <span *ngIf="responsavel.ativo">Ativo</span>
                            <span *ngIf="!responsavel.ativo">Inativo</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-print-layout>