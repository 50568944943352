import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { ICategoriaRiscoModel } from 'src/app/models/categoria-risco.model';
import { AuthService } from 'src/app/services/auth.service';
import { CategoriaRiscoService } from 'src/app/services/categoria-risco.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { IEntendendoEntidadeListaModel } from 'src/app/models/entendendo-entidade-lista.model';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { FiltroQuestionarioUG } from 'src/app/models/filtro-questionario-ug.model';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalQuestionarioNovaVersaoComponent } from './modal-questionario-nova-versao/modal-questionario-nova-versao.component';
import { ModalSolicitarPreenchimentoComponent } from './modal-solicitar-preenchimento/modal-solicitar-preenchimento.component';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { EnumStatusAvaliacaoQuestionarioUG } from 'src/app/models/enum/status-avaliacao-questionario-ug.enum';
import { EnumTipoQuestionarioUG } from 'src/app/models/enum/tipo-questionario-ug.enum';
import { EnumTipoAvaliacaoQuestionarioUG } from 'src/app/models/enum/tipo-avaliacao-questionario-ug.enum';
import { EQuestionarioUGSituacao, EQuestionarioUGSituacaoDescricao } from 'src/app/models/enum/questionario-ug-situacao.enum';
import { QuestionarioUGService } from 'src/app/services/questionarioug.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'versao': return item.versao.trim().toLowerCase();
    case 'data': return item.data.trim().toLowerCase();
    case 'situacao': return item.situacao.trim().toLowerCase();
    case 'ativo':
      return item.ativo;
    default:
      return item.versao.trim().toLowerCase();
  }
};

@Component({
  selector: 'app-aba-entendendo-entidade',
  templateUrl: './aba-entendendo-entidade.component.html',
  styleUrls: ['./aba-entendendo-entidade.component.scss']
})
export class AbaEntendendoEntidadeComponent extends BaseListComponent {

  public dataSource = new MatTableDataSource<IEntendendoEntidadeListaModel>([]);
  public selectedModel: IEntendendoEntidadeListaModel;
  public displayedColumns: string[] = ['selecao', 'versao', 'data', 'situacao', 'status'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public permissoes: IPermissaoModel;
  public filtro = {} as FiltroQuestionarioUG;
  public totalItens: number;
  public pageEvent: any;
  private currentDialogRef: MatDialogRef<any>;

  public botaoNovoDisable = false;
  public botaoEditarDisable = false;
  public botaoExcluirDisable = false;
  public botaoImprimirDisable = false;
  public botaoPreenchimentoDisable = false;
  public botaoImportarDisable = false;
  public botaoVisualizarDisable = false;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private entendendoEntidadeService: EntendendoEntidadeService,
    private questionarioUGService: QuestionarioUGService,
    private router: Router,
    private matDialog: MatDialog,
    private unidadeGestoraService: UnidadeGestoraService,
    private sharedService: SharedService
  ) {
    super();
  }

  async ngOnInit() {
    this.setarPermissoes();
    await this.configuraTela();
    this.buscar();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioEntentendoEntidade, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioEntentendoEntidade, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioEntentendoEntidade, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioEntentendoEntidade, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioEntentendoEntidade, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioEntentendoEntidade, EnumAcaoRecurso.Visualizar);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioEntentendoEntidade, EnumAcaoRecurso.Imprimir);
    this.permissoes.importar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioEntentendoEntidade, EnumAcaoRecurso.Importar);

  }

  async ngAfterViewInit() {
    this.configurarFiltro();
    await this.configuraTela();
    this.buscar();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  public novo() {

    if (!this.semDados) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        source: 'novo'
      };
      this.currentDialogRef = this.matDialog.open(ModalQuestionarioNovaVersaoComponent, dialogConfig);
      this.currentDialogRef.afterClosed().subscribe((data) => {
        this.buscar();
      });
    } else {
      this.router.navigate(['gerar-questionario']);
    }
  }

  public buscar() {
    this.selectedModel = null;
    this.entendendoEntidadeService
      .obter(this.filtro)
      .then((res) => {
        this.totalItens = res.dados.totalItens;
        this.dataSource = new MatTableDataSource<IEntendendoEntidadeListaModel>(res.dados.resultado);
        this.totalItens = res.dados.totalItens;
        this.paginator = this.tableOnePaginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem);
      })
  }

  public reativar(id: number) {
    this.entendendoEntidadeService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Categoria de Risco reativado com sucesso.');
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    this.entendendoEntidadeService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Categoria de Risco inativado com sucesso.');
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar(id: number) {
    const situacoesNaoPermitidas = [
      EQuestionarioUGSituacaoDescricao[EQuestionarioUGSituacao.Pendente],
      EQuestionarioUGSituacaoDescricao[EQuestionarioUGSituacao.Finalizado]
    ];
    const situacaoAtual = this.selectedModel.situacao;
    if (!situacoesNaoPermitidas.includes(situacaoAtual) && this.selectedModel.ativo == true) {
      this.router.navigate([`editar-entendendo-entidade/${id}`]);
    }

  }

  preencher(id: number) {
    const situacaoAtual = this.selectedModel.situacao;

    const situacoesNaoPermitidas = [
      EQuestionarioUGSituacaoDescricao[EQuestionarioUGSituacao.Pendente],
      EQuestionarioUGSituacaoDescricao[EQuestionarioUGSituacao.Finalizado]
    ];

    if (!situacoesNaoPermitidas.includes(situacaoAtual) && this.selectedModel.ativo == true) {
      this.router.navigate([`questionario/pre-planejamento-resposta/${id}`]);
    } else {
      this.exibirMensagemAlerta('Questionário só pode ser preenchido se estiver com Status diferente de Finalizado ou Pendente e também que esteja ativo.');
    }
  }

  public importarArquivo() {
    if (!this.semDados) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        source: 'importarArquivo'
      };
      this.currentDialogRef = this.matDialog.open(ModalQuestionarioNovaVersaoComponent, dialogConfig);
      this.currentDialogRef.afterClosed().subscribe((data) => {
        this.buscar();
      });
    } else {
      this.router.navigate([`importacao-arquivo-questionario`]);
    }

  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }
  isSelect() {
    if (this.selectedModel !== null) {
      return !this.selectedModel?.ativo;
    }
  }

  public excluir(id: number) {
    this.entendendoEntidadeService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Questionário excluído com sucesso.');
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public visualizar(id: number) {
    this.router.navigate([`visualizar-entendendo-entidade/${id}`]);
  }

  public imprimir(id: number) {

    this.questionarioUGService.DownloadArquivoPorIdQuestionario(id).then((res) => {
      console.log(res);
      if (res && res.dados && typeof res.dados.url === 'string' && res.dados.url.trim() !== '') {
        this.http.get(res.dados.url, { responseType: 'blob' }).subscribe((blob) => {
          const fileName = res.dados.nomeArquivo;
          saveAs(blob, fileName);
        })
      }
      else {
        this.router.navigate([`imprimir-entendendo-entidade/${id}`]);
      }
    });





  }

  private configurarFiltro() {
    this.filtro.idUg = this.sharedService.getId();
    this.filtro.tipoQuestionario = 1; // criar um enum
    this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
    this.filtro.colunaOrder = this.sort.active;
    this.filtro.ativo = this.searchAtivo;
    this.filtro.pesquisa = this.searchNome;
    this.filtro.direcao = this.sort.direction;
  }

  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
  }

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.buscar();
  }



  public solicitar(id: number) {
    const situacoesPermitidas = [
      EQuestionarioUGSituacaoDescricao[EQuestionarioUGSituacao.EmElaboracao],
      EQuestionarioUGSituacaoDescricao[EQuestionarioUGSituacao.Avocado]
    ];

    const situacaoAtual = this.selectedModel.situacao;

    if (situacoesPermitidas.includes(situacaoAtual) && this.selectedModel.ativo == true) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-solicitar-preenchimento';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        param1: id
      };

      this.currentDialogRef = this.matDialog.open(ModalSolicitarPreenchimentoComponent, dialogConfig);
      this.currentDialogRef.afterClosed().subscribe((data) => {
        this.buscar();
      });
    }
    else {
      this.exibirMensagemAlerta('Questionario só pode ser Solicitado se estiver no Status de Pendente/Avocado e Ativo');
    }
  }

  public avocar(id: number) {
    const situacao = EQuestionarioUGSituacao.Pendente;
    if (this.selectedModel.situacao == EQuestionarioUGSituacaoDescricao[situacao] && this.selectedModel.ativo == true) {
      this.entendendoEntidadeService
        .Avocar(id)
        .then((res) => {
          if (res.sucesso) {
            this.exibirMensagemSucesso('Questionário avocado com sucesso.');
          } else {
            this.exibirMensagemAlerta(res.mensagem.descricao);
          }
        })
        .catch((err) => {
          this.exibirMensagemErro(err.mensagem);
        })
        .finally(() => {
          this.buscar();
        });
    }
    else {
      this.exibirMensagemAlerta('Questionario só pode ser avocado se estiver no status Pendente e Ativo');
    }

  }

  public async avaliar(id: number) {
    const situacaoEnviado = EQuestionarioUGSituacao.Enviado;
    const situacaoContestado = EQuestionarioUGSituacao.Contestado;
    const situacaoEmValidacao = EQuestionarioUGSituacao.EmValidacao;

    this.sharedService.setTipoQuestionario(EnumTipoQuestionarioUG.EntendendoEntidade);

    if (
      (this.selectedModel.situacao == EQuestionarioUGSituacaoDescricao[situacaoEnviado] ||
        this.selectedModel.situacao == EQuestionarioUGSituacaoDescricao[situacaoContestado] ||
        this.selectedModel.situacao == EQuestionarioUGSituacaoDescricao[situacaoEmValidacao]) &&
      this.selectedModel.ativo == true
    ) {
      await this.questionarioUGService.Avaliar(id);
      this.router.navigate([`avaliar-questionario/${id}`]);
    } else {
      this.exibirMensagemAlerta('Questionario só pode ser avaliado se estiver nos Status de Enviado, Contestado ou Em validação e também que esteja Ativo');
    }

  }

  public isButtonDisabled(): boolean {
    // Verifica se existe um modelo selecionado
    if (!this.selectedModel) {
      return true;
    }

    // Verifica as condições adicionais
    return !this.selectedModel.ativo ||
      !(this.selectedModel.situacao === 'Em Elaboração' || this.selectedModel.situacao === 'Avocado');
  }

  public isButtonDisabledEditar(): boolean {
    // Verifica se existe um modelo selecionado
    if (!this.selectedModel) {
      return true;
    }

    const situacoesNaoPermitidas = [
      EQuestionarioUGSituacaoDescricao[EQuestionarioUGSituacao.Pendente],
      EQuestionarioUGSituacaoDescricao[EQuestionarioUGSituacao.Finalizado]
    ];

    const situacaoAtual = this.selectedModel.situacao;

    // Verifica as condições adicionais
    return !this.selectedModel.ativo || situacoesNaoPermitidas.includes(situacaoAtual);

  }


  async configuraTela() {
    if (this.sharedService.getModoVisualizacaoAbasUnidadeGestora()) {
      this.botaoNovoDisable = true;
      this.botaoImportarDisable = true;
      this.botaoEditarDisable = true;
      this.botaoExcluirDisable = true;
      this.botaoImprimirDisable = false;
      this.botaoPreenchimentoDisable = true;
      this.botaoVisualizarDisable = false;
    }

    this.unidadeGestoraService.obterPorId(this.filtro.idUg)
      .then((res) => {
        if (res.sucesso) {
          if (!res.dados.ativo) {
            this.botaoNovoDisable = true;
            this.botaoImportarDisable = true;
            this.botaoEditarDisable = true;
            this.botaoExcluirDisable = true;
            this.botaoImprimirDisable = true;
            this.botaoPreenchimentoDisable = true;
            this.botaoVisualizarDisable = true;
          }
        }
      });

    this.entendendoEntidadeService.DownloadArquivo(this.filtro.idUg)
      .then((res) => {
        if (res.dados.url != '') {
          this.botaoEditarDisable = true;
          this.botaoPreenchimentoDisable = true;
          this.botaoVisualizarDisable = true;
        }
      })

  }


}
