import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss']
})
export class PrintLayoutComponent {
  @Input() habilitado = true;
  @Output() cancelar = new EventEmitter<any>();

  dataAtual: string;
  
  constructor(
    public authService: AuthService,
  ) {}

  print() {
    const currentDate = new Date();
    this.dataAtual = currentDate.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });


    // this.usuarioEmissor = this.authService.usuario?.nome;
    setTimeout(() => {
      // Forçar a impressão após a contagem de páginas ser calculada
      window.print();
    }, 100);
  }
  
  cancelarClick() {
    this.cancelar.emit();
  }
}