import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { IBaseModel } from '../models/base.model';
import { IMaginitudesEscalaRisco } from '../models/maginitudes-escala-risco.model';

@Injectable({
  providedIn: 'root'
})
export class MagnitudesEscalaRiscoService extends BaseService {


  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(escalaId: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IMaginitudesEscalaRisco[]>> {
    let params = new HttpParams();
    params = params.append('escalaId', escalaId || '0');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IMaginitudesEscalaRisco[]>>(`${this.apiBaseUrl}/magnitudesEscalaRisco`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IMaginitudesEscalaRisco>> {
    return this.httpClient
      .get<IBaseModel<IMaginitudesEscalaRisco>>(`${this.apiBaseUrl}/magnitudesEscalaRisco/${id}`)
      .toPromise();
  }

  public async inserir(data: IMaginitudesEscalaRisco): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/magnitudesEscalaRisco`, data)
      .toPromise();
  }

  public async atualizar(data: IMaginitudesEscalaRisco): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/magnitudesEscalaRisco/${data.id}`, data)
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/magnitudesEscalaRisco/${id}/excluir`, {})
      .toPromise();
  }
}