import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { IBaseModel } from '../models/base.model';
import { IFormulaRiscoResidual } from '../models/formula-risco-residual.model';

@Injectable({
  providedIn: 'root'
})
export class FormulaRiscoResidualService extends BaseService {


  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(criteriosAvaliacaoRiscosId: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IFormulaRiscoResidual[]>> {
    let params = new HttpParams();
    params = params.append('criteriosAvaliacaoRiscosId', criteriosAvaliacaoRiscosId || '0');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IFormulaRiscoResidual[]>>(`${this.apiBaseUrl}/formulaRiscoResidual`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IFormulaRiscoResidual>> {
    return this.httpClient
      .get<IBaseModel<IFormulaRiscoResidual>>(`${this.apiBaseUrl}/formulaRiscoResidual/${id}`)
      .toPromise();
  }

  public async inserir(data: IFormulaRiscoResidual): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/formulaRiscoResidual`, data)
      .toPromise();
  }

  public async atualizar(data: IFormulaRiscoResidual): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/formulaRiscoResidual/${data.id}`, data)
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/formulaRiscoResidual/${id}/excluir`, {})
      .toPromise();
  }
}