import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IArquivoModel } from 'src/app/models/arquivo.model';
import { AvaliacaoRequest, IListaQuestionarioRespostaUGModel, QuestionarioUGItensResposta } from 'src/app/models/questionario-ug.model';
import { AuthService } from 'src/app/services/auth.service';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { PerguntaService } from 'src/app/services/pergunta.service';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ModalDelegarComponent } from './modal-delegar/modal-delegar.component';
import { ResponsaveisService } from 'src/app/services/responsaveis.service ';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ModalContestarComponent } from './modal-contestar/modal-contestar.component';
import Swal from 'sweetalert2';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EnumStatusAvaliacaoQuestionarioUG } from 'src/app/models/enum/status-avaliacao-questionario-ug.enum';
import { EnumTipoAvaliacaoQuestionarioUG } from 'src/app/models/enum/tipo-avaliacao-questionario-ug.enum';
import { SelectionModel } from "@angular/cdk/collections";
import { SharedService } from "../../services/shared.service";

interface Question {
  question: string;
  responseType: string;
  status: string;
  formControlName: string;
  assignedTo?: string;
}



@Component({
  selector: 'app-pre-planejamento-resposta-form',
  templateUrl: './pre-planejamento-resposta-form.component.html',
  styleUrls: ['./pre-planejamento-resposta-form.component.scss']
})
export class PrePlanejamentoRespostaFormComponent extends BaseFormComponent implements OnInit {
  public model= {} as IListaQuestionarioRespostaUGModel;
  formGroup: FormGroup;
  arquivo: File | null = null;
  //displayedColumns: string[] = ['checkbox', 'question', 'responseType', 'status', 'response', 'assignedTo'];
  displayedColumns: string[] = ['pergunta', 'tipoEntrada', 'status', 'nomeDelegadoA'];
  displayedColumnsName: string[] = ['Pergunta/Seção', 'Tipo de Entrada', 'Status', 'Delegado a']
  public dataSource = new MatTableDataSource<any>([]);
  public dataSource2: any[] = [];
  imprimir: boolean;
  contestado: boolean = false;
  selectedItems: QuestionarioUGItensResposta[] = [];
  selectedIds: number[] = [];
  exibeCamposStatusEmReplica: boolean;
  habilitaBotaoFinalizar: boolean = false;
  avaliacao: AvaliacaoRequest;
  isDisabledStatusEmReplica: boolean = false;
  selection = new SelectionModel<any>(true, []);
  tipoQuestionario: number;
  config: AngularEditorConfig = {
    editable: !this.visualizar,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor(private fb: FormBuilder,
    private questionarioUGService: QuestionarioUGRespostaService,
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    private responsaveisService: ResponsaveisService,
    public matDialog: MatDialog,
    private sharedService: SharedService
    ) {
      super(route, toastr, router, localeService, matDialog);
      this.formGroup = this.fb.group({
        checkboxs: this.fb.array([]),
        respostas: this.fb.array([]),
        arquivos: this.fb.array([]) // Novo FormArray para os arquivos
    });
    this.unidadeGestoraId = 307;

  }
  private unidadeGestoraId = 307;


  ngOnInit(): void {
    this.buscar();
    this.tipoQuestionario = this.sharedService.getTipoQuestionario();
    this.route.data.subscribe(data => {
      this.imprimir = data['imprimir']; // Acesse o valor de imprimir dos dados da rota
    });
  }

  print(): void {
    window.print();
  }

  async onSalvar(salvarEFechar: boolean) {
    const respostasFormArray = this.formGroup.get('respostas') as FormArray;
    this.dataSource.data.forEach((item, index) => {
      item.resposta = respostasFormArray.at(index).value;
    });
    const res = await this.questionarioUGService.salvarRespostasQuestionarioPorId(this.id, this.dataSource.data);
    if (res.sucesso) {
      if(salvarEFechar == true)
      {
        this.router.navigate([`/questionario/pre-planejamento`]);
      }
      else
      {
        this.exibirMensagemSucesso('Registro Salvo com Sucesso!')
        this.buscar();
        window.location.reload();
      }

    }
    else
    {
        this.exibirMensagemErro(res.mensagem.descricao);
    }

  }


async onEnviar(){
    const respostasFormArray = this.formGroup.get('respostas') as FormArray;
    this.dataSource.data.forEach((item, index) => {
      item.resposta = respostasFormArray.at(index).value;
    });

    this.questionarioUGService.enviarRespostasQuestionarioPorId(this.id, this.dataSource.data)
    .then((res) => {
      if (res.sucesso) {

        this.router.navigate([`/questionario/pre-planejamento`]);
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })

  }
  onCancelar(){
    if(this.visualizar || this.imprimir){
      this.router.navigate([`/questionario/pre-planejamento`]);
    }else{
      Swal.fire({
        title: 'Atenção',
        text: "Todas as informações preenchidas serão perdidas. Deseja continuar? ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((res) => {
        if (res.value) {
          this.router.navigate([`/questionario/pre-planejamento`]);
        }
      })
    }
  }

  async modalDelegar(){
    const idsParaDelegar = this.selectedIds;
    const res = await this.responsaveisService.obter(
      this.model.idUG, '','','', true
    );

    const selectedItemIds = this.selectedItems.map(item => item.idItemQuestionario);

    if (res.sucesso) {
      try {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '500px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          questionarioId: idsParaDelegar,
          dados: res.dados,
        };

        const modal = this.matDialog.open(
          ModalDelegarComponent,
          dialogConfig
        );

        modal.afterClosed().subscribe(async (result) => {
          if (result === true) {
            this.submit = true;
            this.buscar();
            this.exibirMensagemSucesso("Registro delegado com sucesso");
          }
        });
      } catch (err) {
        this.exibirMensagemErro(err.mensagem.descricao);
      }
    }

  }

  enviarArquivo(){

  }

  onConcordar(){

    let idsSelecionados: number[] = [];
    if(this.selectedIds.length > 0){
      idsSelecionados = this.selectedIds;
    }else{
      // @ts-ignore
      idsSelecionados = this.selection.selected.map(item => {
        if (item.idTipoSecaoOuPergunta != 1) {
          return item.idItemQuestionario;
        }
        return null;
      }).filter(id => id !== null);
    }

    if(!idsSelecionados || idsSelecionados.length == 0){
      this.exibirMensagemErro("Favor selecionar ao menos 1 pergunta.")
      return false;
    }

    const idsSelecionadosString: string = idsSelecionados.join(',');
    this.avaliacao = {
      statusAvaliacao: EnumStatusAvaliacaoQuestionarioUG.concordo,
      texto: "",
      tipoAvaliacao: EnumTipoAvaliacaoQuestionarioUG.replica
    };

    Swal.fire({
      title: 'Atenção',
      text: "Concordar com as respostas selecionadas? Caso confirme, todas serão finalizadas! ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((res) => {
      if(res.isConfirmed)
          this.questionarioUGService.fazerAvaliacaoOuReplica(idsSelecionadosString, this.avaliacao)
          .then((res) => {
            if (res.sucesso) {
              this.exibirMensagemSucesso('Registro Atualizado com sucesso');
              this.buscar();
              this.selectedIds = [];
            }
            else{
              this.exibirMensagemErro(res.mensagem.descricao);
            }
          })
    })

  }

  onContestar(){

    const idsSelecionados = this.selectedIds;
    const idsSelecionadosString: string = idsSelecionados.join(',');
    const perguntaSelecionada = this.model.respostas.find(item => idsSelecionados.includes(item.idItemQuestionario));
    // Verifica se há mais de uma pergunta selecionada
    if (idsSelecionados.length > 1) {
      this.exibirMensagemAlerta('Só pode ter um registro selecionado para contestar');
      return;
    }

    // Verifica se há pelo menos uma pergunta selecionada
    if (idsSelecionados.length === 0) {
      this.exibirMensagemAlerta('Selecione uma pergunta para contestar');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '1000px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      ids: idsSelecionadosString,
      pergunta: perguntaSelecionada.pergunta,
      resposta: perguntaSelecionada.resposta,
      tipoQuestionarioId: this.model.tipoQuestionarioId,
      tipoEntrada: perguntaSelecionada.tipoEntrada,
      perguntaOpcao: perguntaSelecionada.perguntaOpcao,
      tipoQuestionario: this.tipoQuestionario,
      idQuestionario: this.id
    };

    const modal = this.matDialog.open(ModalContestarComponent, dialogConfig);
    modal.afterClosed().subscribe((data) => {
      if(data.success){
        // this.buscar();
        // window.location.reload();
        this.selection.clear();
        this.buscar();

       }
    })

  }

  onFinalizar(){

    let idsSelecionados: number[] = [];
    if(this.selectedIds.length > 0){
      idsSelecionados = this.selectedIds;
    }else{
      // @ts-ignore
      idsSelecionados = this.selection.selected.map(item => {
        if (item.idTipoSecaoOuPergunta != 1) {
          return item.idItemQuestionario;
        }
        return null;
      }).filter(id => id !== null);
    }
    const idsSelecionadosString: string = idsSelecionados.join(',');

    this.questionarioUGService.FinalizarPerguntaQuestionario(idsSelecionadosString)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso("Registro finalizado com sucesso!");
        this.buscar();
        window.location.reload();
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })

  }

  buscar() {
    this.questionarioUGService.ObterRespostaQuestionario(this.id)
      .then((res) => {
        if (res.sucesso) {
          this.model = res.dados;
          if(this.model.status == 'Em réplica')
          {
            this.config.editable = false;
            this.isDisabledStatusEmReplica = true;
            this.exibeCamposStatusEmReplica = true;
            this.displayedColumns = ['pergunta', 'tipoEntrada', 'status', 'nomeDelegadoA', 'tipoAvaliacaoAuditor', 'avaliacaoAuditor'];
            this.displayedColumnsName = ['Pergunta/Seção', 'Tipo de Entrada', 'Status', 'Delegado a', 'Avaliação do Auditor', 'Resposta Revidada do Auditor']
          }
          else
          {
            this.displayedColumns = ['pergunta', 'tipoEntrada', 'status', 'nomeDelegadoA'];
            this.displayedColumnsName = ['Pergunta/Seção', 'Tipo de Entrada', 'Status', 'Delegado a']
            this.exibeCamposStatusEmReplica = false;
          }
          this.dataSource = new MatTableDataSource<QuestionarioUGItensResposta>(res.dados.respostas);
          this.dataSource2 = res.dados.respostas;
          // Limpa o FormArray antes de adicionar novos controles
          const respostasFormArray = this.formGroup.get('respostas') as FormArray;
          respostasFormArray.clear();

          //const checkboxesFormArray = this.fb.array([]); // Novo FormArray para os checkboxes
          const checkboxesFormArray = this.formGroup.get('checkboxs') as FormArray;
          checkboxesFormArray.clear();
          this.selectedIds = [];
          this.selection.clear();

          // Adiciona controles ao FormArray dinamicamente
          res.dados.respostas.forEach(item => {
            respostasFormArray.push(new FormControl(item.resposta));
            checkboxesFormArray.push(new FormControl(item.selected || false));
          });
          if (this.model.status == 'Contestado'){
            this.contestado = true;
            this.config.editable = false
          }

         } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => this.exibirMensagemErro(err));
  }

  buscarArquivoAnexado() {
    this.questionarioUGService.ObterRespostaQuestionario(this.id)
      .then((res) => {
        if (res.sucesso) {
          this.model = res.dados;

          this.dataSource = new MatTableDataSource<QuestionarioUGItensResposta>(res.dados.respostas);
          this.dataSource2 = res.dados.respostas;
         } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => this.exibirMensagemErro(err));
  }



  toggleSelectAll(checked: boolean) {
    const checkboxesFormArray = this.formGroup.get('checkboxes') as FormArray;
    if (checkboxesFormArray) {
      const checkboxesFormArray = this.formGroup.get('checkboxes') as FormArray;
      checkboxesFormArray.controls.forEach(control => control.setValue(checked));
  } else {
    return false; // Ou qualquer valor padrão que faça sentido no seu contexto
  }
  }



  isIndeterminate() {
    const checkboxesFormArray = this.formGroup.get('checkboxes') as FormArray;
    if (checkboxesFormArray) { // Adicione esta verificação
      const numSelected = checkboxesFormArray.controls.filter(control => control.value).length;
      return numSelected > 0 && numSelected < checkboxesFormArray.controls.length;
    } else {
      return false; // Ou qualquer valor padrão que faça sentido no seu contexto
    }
  }

  updateSelectedItems(item: QuestionarioUGItensResposta) {
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      const index = this.selectedItems.findIndex(selectedItem => selectedItem === item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    }
  }

  onFileSelected(event: any, index: number): void {
    const file: File = event.target.files[0];
    if (file) {
        // Verifica se a extensão do arquivo é PDF
        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'jpe', 'jfif', 'gif', 'png', 'bmp', 'tiff', 'exif', 'doc', 'docx', 'zip', 'csv', 'txt', 'xls', 'ppt', 'pps', 'odt', 'odf', 'html'];

        if (!allowedExtensions.includes(fileExtension)) {
            this.toastr.error('Extensão do arquivo selecionado não é válida !!!', 'Extensão inválida');
            this.arquivo = null;
        } else {
            this.arquivo = file;
          // AQUI COMEÇA UMA NOVA HISTORIA E OUTRA TENTATIVA
          const idItemQuestionario = this.dataSource.data[index].idItemQuestionario;

          const arquivoModel: IArquivoModel = {
            id: 0,
            nomeArquivo: this.arquivo.name,
            entidadeArquivo: 1,
            arquivo: this.arquivo,
            url: ''
          };

          this.questionarioUGService.anexarArquivo(idItemQuestionario ,arquivoModel).then(
            (response) => {
              this.buscarArquivoAnexado();
              // importante nao colocar o reload aqui, senao os dados digitados na pagina sao perdidos bug 38698
              // window.location.reload();
            },
            (error) => {
              this.exibirMensagemErro(error.mensagem.descricao);
            }
          );
      }
    }
}


  getRespostaFormControl(index: number): FormControl {
    const respostasFormArray = this.formGroup.get('respostas') as FormArray;
    const control = respostasFormArray.at(index) as FormControl;
    if (this.visualizar || this.imprimir) {
      control.disable();
    } else {
      control.enable();
    }

    return control;
  }

  getCheckBoxFormControl(index: number): FormControl {
    const checkBoxsFormArray = this.formGroup.get('checkboxs') as FormArray;
    return checkBoxsFormArray.at(index) as FormControl;
  }

  getArquivoFormControl(index: number): FormControl {
    const arquivosFormArray = this.formGroup.get('arquivos') as FormArray;
    return arquivosFormArray.at(index) as FormControl;
}
  // toggleAll(event:any){
  //   if (event.checked) {
  //     this.selection.select(...this.dataSource2);
  //     this.dataSource2.forEach((item)=>{
  //
  //       if(item.idTipoSecaoOuPergunta == 2){
  //         this.selectedIds.push(item.idItemQuestionario);
  //       }
  //     });
  //
  //   } else {
  //     this.selection.clear();
  //     this.selectedIds.pop();
  //   }
  //   this.habilitaBotaoFinalizar = !this.selection.isEmpty();
  // }

  masterToggle() {
    if(this.isAllSelected()){
      this.selection.clear();
      this.habilitaBotaoFinalizar = false;
    }else {
      this.dataSource2.forEach(row => this.selection.select(row));
      this.habilitaBotaoFinalizar = true;
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource2.filter(x => x.idTipoSecaoOuPergunta  != 1).length;
    return numSelected === numRows;

  }
  checkExists(value):boolean{
    return this.selectedIds.includes(value);
  }
onCheckboxChange(event: MatCheckboxChange, element: any) {
  if (event.checked) {
    if(!this.checkExists(element.idItemQuestionario)){
      this.selectedIds.push(element.idItemQuestionario);
      this.selection.toggle(element);
    }
  } else {
    const index = this.selectedIds.indexOf(element.idItemQuestionario);
    if (index > -1) {
      this.selectedIds.splice(index, 1);
      this.selection.deselect(element);
    }
  }
  this.habilitaBotaoFinalizar = this.selectedIds.length > 0;
}

isAllFinalized() {
  const filteredItems = this.dataSource.data.filter(item => item.idTipoSecaoOuPergunta === 2);
  const allFinalized = filteredItems.every(item => item.status === 'Finalizado' || item.status === 'Contestado');
  return allFinalized;
}

isStatusEmReplica(){
  return this.model.status == 'Em réplica';
}

isHabilitaBotaoFinalizar(){
  return this.habilitaBotaoFinalizar;
}

DesabilitaBotaoDelegar(){
  return this.selectedIds.length == 0;
}


}
