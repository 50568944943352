<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">
                            <i class="fas fa-home"></i>
                            Página Inicial</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/criteriosAvaliacao']">Critários de Avaliação de Riscos</a>
                    </li>
                    <li class="breadcrumb-item active">{{ titulo }}</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-lightblue">
                    <div class="card-header">
                        <h3 class="card-title mb-0">
                            <!-- <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
                            <i *ngIf="!this.id" class="far fa-file pr-2"></i> -->
                            {{ titulo }}
                        </h3>
                    </div>

                    <ul class="nav nav-tabs ml-auto p-0 mt-2">
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ active: aba === 0 }" (click)="mudarAba(0)">Dados</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ active: aba === 1 }" (click)="mudarAba(1)">Risco Inerente
                                (RI)</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ active: aba === 2 }" (click)="mudarAba(2)">Controles</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ active: aba === 3 }" (click)="mudarAba(3)">Niveis de Risco
                                Residual (RR)</a>
                        </li>
                    </ul>
                    <div>
                        <div class="card-body pb-0">
                            <!-- ABA DADOS -->
                            <div #abaDados [hidden]="aba !== 0">
                                <app-criterios-avaliacao-form-dados></app-criterios-avaliacao-form-dados>
                            </div>
                            <!-- ABA RISCO INERENTE -->
                            <div #abaRiscoInerente [hidden]="aba !== 1">
                                <app-criterios-avaliacao-form-risco-inerente></app-criterios-avaliacao-form-risco-inerente>
                            </div>
                            <!-- ABA CONTROLE -->
                            <div #abaControle [hidden]="aba !== 2">
                                <app-criterios-avaliacao-form-controles></app-criterios-avaliacao-form-controles>
                            </div>
                            <!-- ABA NIVEIS DE RISCO RESIDUAL -->
                            <div #abaNiveisRisco [hidden]="aba !== 3">
                                <app-criterios-avaliacao-form-niveis-risco-residual></app-criterios-avaliacao-form-niveis-risco-residual>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="btn-group">
                                <button class="btn btn-primary btn-sm mr" (click)="salvarDados()">
                                    <i class="far fa-save"></i> Salvar
                                </button>
                                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon"
                                    data-toggle="dropdown" aria-expanded="false">
                                </button>
                                <div class="dropdown-menu" role="menu">
                                    <a class="dropdown-item" (click)="salvarDados()">Salvar</a>
                                    <a class="dropdown-item" (click)="salvarDados()">Salvar e fechar</a>
                                </div>
                            </div>

                            <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm"
                                (click)="onBack()">
                                <i class="fas fa-times"></i> Cancelar
                            </button>

                            <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm"
                                (click)="onBack()">
                                <i class="fas fa-times"></i> Sair
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>