<div class="card-body p-0">
	<div class="mat-elevation-z4">
		<mat-toolbar class="table-header">
			<mat-toolbar-row>
				<button type="button" class="btn btn-success btn-sm mr-1" *ngIf="!visualizar && hiddenEdicao === false"
					(click)="exibirModalAdicionarAmostra()">
					Adicionar
					<i class="far fas fa-plus fa-sm"></i>
				</button>
			</mat-toolbar-row>
		</mat-toolbar>
		<div class="w-100 scroll">
			<table mat-table [dataSource]="dataSource" [hidden]="semDados" class="mat-elevation-z1 w-100 " matSort
				matSortDisableClear #tabela1="matSort">
				<!-- Position Column -->
				<ng-container matColumnDef="nome">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
					<td mat-cell style="max-width: 200px;" *matCellDef="let element">{{ element?.nome }}</td>
				</ng-container>

				<!-- Descrição Column -->
				<ng-container matColumnDef="descricao">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</th>
					<td style="max-width: 400px;" mat-cell *matCellDef="let element">
						{{ element?.descricao }}
					</td>
				</ng-container>

				<!-- Sigla Column -->
				<ng-container matColumnDef="ug">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>UG - Sigla</th>
					<td mat-cell *matCellDef="let element">
						<span class="badge badge-secondary ml-1"
							*ngFor="let items of element?.amostraUnidadeGestoras">{{ items?.unidadeGestora?.sigla }}{{ "
							" }}</span>
					</td>
				</ng-container>

				<!-- Weight Column -->
				<ng-container matColumnDef="arquivos">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivos</th>
					<td mat-cell *matCellDef="let element">
						<a class="icon-btn btn btn-info btn-xs text-left ml-1" data-toggle="collapse"
							href="#collapseExample-{{element.amostraArquivos[0]?.id}}" role="button"
							aria-expanded="false">
							Arquivos
							<i class="ml-2 fas fa-file"></i>
						</a>

						<div class="collapse" id="collapseExample-{{ element.amostraArquivos[0]?.id }}">
							<small *ngIf="!element?.amostraArquivos[0]" class="ml-1"> Não possui arquivos</small>
							<div *ngFor="let items of element?.amostraArquivos" class="ml-1">
								<div class="">
									<small (click)="
										Download(items?.id, items?.nome)
									" class="download">{{ items.nome }}</small>
								</div>
							</div>
						</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="acoes">
					<th mat-header-cell *matHeaderCellDef class="text-center">
						<div *ngIf="!visualizar">Ações</div>
					</th>
					<td mat-cell *matCellDef="let element">
						<div class="w-100 d-flex justify-content-center">
							<button *ngIf="!visualizar" class="icon-btn btn btn-primary btn-xs text-left"
								(click)="exibirModalAdicionarAmostra(element)">
								<i class="far fas fa-pen fa-sm"></i>
							</button>
							<button  class="icon-btn btn btn-info btn-xs text-left ml-1"
								(click)="imprimir(element)">
								<i class="far fas fa-print fa-sm"></i>
							</button>
							<button *ngIf="!visualizar" type="button" class="btn btn-danger btn-xs text-left ml-1"
								(click)="excluirAmostra(element)">
								<i class="far fa-trash-alt"></i>
							</button>
							<button class="icon-btn btn btn-primary btn-xs text-left ml-1"
								(click)="visualizarModalAdicionarAmostra(element)">
								<i class="far fas fa-eye fa-sm"></i>
							</button>
						</div>

					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>
		</div>
		<h5 [hidden]="!semDados" class="table-no-data">
			Nenhum registro encontrado.
		</h5>
		<mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons>
		</mat-paginator>
	</div>
</div>