export enum EnumRecursoPerfil {

  Cadastros = 'CadastrosBasicos',
  ManterPAA = 'Manter PAA',
  ManterPEA = 'Manter PEA',
  ManterOPA = 'Manter OPA',
  Configuracoes = 'Configurações',
  Planejamento = 'Planejamento',
  ManterOSA='Manter OSA',
  Auditoria='Auditoria',
  Aprovacoes='Aprovações',
  LogsDoSistema='Logs do Sistema',
  Aberto='Aberto',
  CadastroCategoriaRisco='Cadastro - Categoria Risco',
  PrePlanejamento='RelacionamentoComUG - PrePlanejamento',
	CadastroRiscos = "Cadastro - Riscos",
	CriteriosAvaliacaoRiscos = "Cadastro - Riscos",
  ModeloQuestionario='ModeloQuestionario',
  QuestionarioMaturidade='QuestionarioMaturidade',
  QuestionarioEntentendoEntidade='QuestionarioEntentendoEntidade',
  TipoUniversoAuditavel = 'TipoUniversoAuditavel'

}
