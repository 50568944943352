<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i> Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item active">
						Avaliar Questionário
					</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-outline card-lightblue">
        <div class="card-header">
          <h3 class="card-title mb-0">
            <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
            <i *ngIf="!this.id" class="far fa-file pr-2"></i> Avaliar Questionário
          </h3>
        </div>

        <div class="card-body">

          <div class="row">
            <div class="form-group col-md-4">
              <label for="pea" class="mb-1">Nome da Funcionalidade </label>
              <input
                type="text"
                disabled
                id="pea"
                class="form-control form-control-sm"
                [ngModel]="model.nomeFuncionalidade"
                />
            </div>
          </div>

            <div class="row">
              <button
                type="button"
                class="btn btn-danger btn-sm mr-1"
                (click)="onAvaliar()"
                [disabled]="isButtonAvaliarDisabled">
                <i class="fas fa-check"></i> Avaliar
              </button>
              <button
              type="button"
              class="btn btn-primary btn-sm mr-1"
              (click)="onConcordar()">
              <i class="fas fa-pencil-alt"></i> Concordar
              </button>
              <button
              type="button"
              class="btn btn-primary btn-sm mr-1"
              (click)="onReavaliar()"
              [disabled]="isButtonReAvaliarDisabled">
              <i class="fas fa-pencil-alt"></i> Reavaliar
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <form [formGroup]="formGroup">
    <div formArrayName="respostas">
      <table *ngIf="dataSource && displayedColumns.length" mat-table [dataSource]="dataSource2" class=" mat-table mat-elevation-z8" style="margin: 5px;">
        <!-- Coluna de Pergunta e Resposta -->
        <ng-container matColumnDef="questionAndResponse">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element; let index = index" class="question-response-cell">
            <ng-container *ngIf="element.idTipoSecaoOuPergunta === 1">
              <h3>{{ element.pergunta }}</h3>
            </ng-container>

            <ng-container *ngIf="element.idTipoSecaoOuPergunta === 2">
              <mat-checkbox   (click)="$event.stopPropagation()"
                    [checked]="selection.isSelected(element)"
                    (change)="onCheckboxChange($event, element)"
                    [disabled]="element.tipoAvaliacaoAuditor == 'Concordo' || element.tipoReplica == 'Concordo'">
                <label style="padding: 10px; white-space: 'pre-line'; word-wrap: break-word;">{{element.pergunta}}</label>
              </mat-checkbox>
              <!-- Campo de resposta da pergunta -->
              <div class="mat-form-field-wrapper">
                <div [innerHTML]="getRespostaFormControl(index).value"></div>
              </div>

            </ng-container>
          </td>
        </ng-container>
        <!-- Coluna Situação -->
        <ng-container matColumnDef="situacao">
          <th mat-header-cell *matHeaderCellDef class="header-bold">Situação</th>
          <td mat-cell *matCellDef="let element">
            {{ element.idTipoSecaoOuPergunta === 2 ? element.tipoAvaliacaoAuditor : "" }}
          </td>
        </ng-container>

        <!-- Header e Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="card-footer">
        <button
          type="button"
          *ngIf="!visualizar && !imprimir"
          class="btn btn-primary btn-sm mr-1"
          (click)="onEncerrarAvaliacao()"
          [disabled]="!isTodosAvaliados()">
          <i class=""></i> Encerrar Avaliação
        </button>
        <button
          type="button"
          *ngIf="!visualizar && !imprimir"
          class="btn btn-success btn-sm mr-1"
          (click)="onEnviarReplica()"
          [disabled]="!isTodosAvaliados() || isButtonEnviarReplicaDisabled">
          <i class=""></i> Enviar para Réplica
        </button>
        <button
          type="button"
          *ngIf="!visualizar && !imprimir"
          class="btn btn-outline-secondary btn-sm mr-1"
          (click)="onFechar()">
          <i class=""></i> Fechar
        </button>
      </div>
    </div>
  </form>
</div>

<div class="container">

</div>
