<app-print-layout (cancelar)="cancelar()">
  <div class="p-4 content">
    <div class="card card-outline">
      <div class="card-body">
          <div
            [class.minuta]="
              model?.status?.nome !== 'Aprovado' || model?.status?.nome !== 'Homologado'
            "
          ></div>
        <div>
          <div
            class="row border border-dark d-flex justify-content-center align-items-center"
            style="height: 70px;"
          >
            <div class="col-12 text-center">
              <p class="h5 font-weight-bold text-center"
                >Ordem de Serviço de Auditoria nº {{ model?.numero }} /
                {{ model?.ano }}</p
              >
            </div>
          </div>
          <div class="row border border-top-0 border-dark">
            <div class="col-4">
              <strong>Origem:</strong>
              <p>{{ model?.origem.nome }}</p>
            </div>
            <div class="col-4 border border-top-0 border-bottom-0 border-dark">
              <strong>Tipo:</strong>
              <p>{{ model?.tiposAuditorias?.nome }}</p>
            </div>
            <div class="col-4">
              <strong>Subtipo:</strong>
              <p *ngFor="let item of model?.subTiposAuditorias">
                <strong>Nome: </strong>{{ item?.nome }}
              </p>
            </div>
          </div>
          <div class="row border border-top-0 border-dark">
            <div class="col-12">
              <strong>UG a ser auditada:</strong>
              <p>{{ model?.unidadeGestora.nome }}</p>
            </div>
          </div>
          <div class="row border border-top-0 border-dark">
            <div class="col-12">
              <strong>Responsável da UG:</strong>
              <p>{{ model?.unidadeGestora?.responsavel }}</p>
            </div>
          </div>
          <div class="row border border-top-0 border-dark">
            <div class="col-12">
              <strong>Objeto:</strong>
              <p>
                {{ model?.objeto }}
              </p>
            </div>
          </div>
          <div class="row border border-top-0 border-dark">
            <div
              class="px-0 col-3 border border-top-0 border-left-0 border-bottom-0 border-dark"
            >
              <div class="border-bottom border-dark w-100 col-sm-12">
                <strong>Periodo a ser auditado</strong>
              </div>
  
              <div class="row">
                <div
                  class="col-sm-6 p-2 border border-top-0 border-left-0 border-bottom-0 border-dark"
                >
                  <strong class="col-sm-6 p-2">Inicio:</strong>
                  <p class="p-2">
                    {{ model?.dataInicioPeriodoAuditado | date: "dd/MM/yyyy" }}
                  </p>
                </div>
                <div class="col-sm-6 p-2">
                  <strong>Fim:</strong>
                  <p>{{ model?.dataFimPeriodoAuditado | date: "dd/MM/yyyy" }}</p>
                </div>
              </div>
            </div>
            <div
              class="px-0 col-3 border border-top-0 border-left-0 border-bottom-0 border-dark"
            >
              <div class="border-bottom border-dark w-100 col-sm-12">
                <strong>Prazo da execução</strong>
              </div>
              <div class="row">
                <div
                  class="col-sm-6 p-2 border border-top-0 border-left-0 border-bottom-0 border-dark"
                >
                  <strong class="col-sm-6 p-2">Inicio:</strong>
                  <p class="p-2">
                    {{ model?.prazoExecucaoInicio | date: "dd/MM/yyyy" }}
                  </p>
                </div>
                <div class="col-sm-6 p-2">
                  <strong class="col-sm-6 p-2">Fim:</strong>
                  <p class="p-2">
                    {{ model?.prazoExecucaoFim | date: "dd/MM/yyyy" }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="px-0 col-3 border border-top-0 border-left-0 border-bottom-0 border-dark text-center"
            >
              <div class="border-bottom border-dark w-100 col-sm-12">
                <strong class="w-100 text-center">
  
                  Prazo manifestação do Auditado:</strong
                >
              </div>
  
              <p class="text-center p-2">
                {{ model?.prazoManifestacaoAuditado | date: "dd/MM/yyyy" }}
              </p>
            </div>
            <div class="px-0 col-3 text-center">
              <div class="border-bottom border-dark w-100 col-sm-12">
                <strong class="w-100">Prazo do relatório:</strong>
              </div>
  
              <p class="p-2">
                {{ model?.prazoEntregaRelatorio | date: "dd/MM/yyyy" }}
              </p>
            </div>
          </div>
          <div class="row border border-top-0 border-dark" *ngIf="model?.osaEquipe?.length <= 0; else auditor" >
            <div class="col-12">
              <p class="text-center"><strong >Nenhum Auditor Encontrado</strong></p>
            </div>
          </div>
          <ng-template #auditor>
            <div class="row border border-top-0 border-dark" *ngFor="let item of model?.osaEquipe">
              <div class="col-6">
                <p><strong>Nome: </strong> {{item?.nomeAuditor}}</p>
              </div>
              <div class="col-6">
  
                <p><strong>Cargo:</strong> {{item?.cargo}}</p>
              </div>
            </div>
          </ng-template>
  
  
          <div class="row border border-right border-dark border-top-0">
            <div class="col-12">
              <strong>Nota de rodapé:</strong>
              <p>
                {{ model?.notaRodape }}
              </p>
            </div>
          </div>
  
          <div class="row border border-top-0 border-dark">
            <div class="col-4 border-right border-dark">
              <strong>Elaboração:</strong>
              <div class="d-flex flex-column">
  
                  <p>{{model?.nomeCoordenador}}</p>
              </div>
            </div>
  
            <div class="col-8" *ngIf="model?.assinaturas?.length <= 0">
              <p class="text-center"><strong >Nenhuma aprovação</strong></p>
            </div>
  
            <div class="col-8" *ngIf="model?.assinaturas?.length > 0">
              <div class="row">
                <div class="col-12" >
                  <strong>Aprovações:</strong>
                  <ol>
                  <li *ngFor="let item of model?.assinaturas">
  
                    <div class="d-flex flex-column">
                    <p *ngIf="item?.situacao === 1" >
                        {{ item?.nome }}({{ item?.papel }}) aprovou.
                    </p>
  
                    <p *ngIf="item?.situacao === 2" >
                        {{ item?.nome }}({{ item?.papel }}) reprovou.
                    </p>
  
                    <p *ngIf="item?.situacao === 3">
                        {{ item?.nome}}({{ item?.papel }}) enviou para aprovação.
                    </p>
  
                    <p *ngIf="item.situacao === 4">
                        {{ item?.nome }}({{ item?.papel }}) homologou.
                    </p>
                    </div>
                  </li>
                </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-print-layout>