<app-print-layout (cancelar)="cancelar()">
  <div class="relatorio content">
    <div class="card card-outline">
      <div class="card-body">
        <hr />
        <section id="indicesECriterios">
          <h4>MATRIZ DE PLANEJAMENTO</h4>
          <div>
            <table>
              <tr>
                <th>QUESTÕES DE AUDITORIA</th>
                <th>CLASSIFICAÇÃO DA QUESTÃO</th>
                <th>INFORMAÇÕES REQUERIDAS / FONTES DE INFORMAÇÃO</th>
                <th>LEGISLAÇÃO APLICÁVEL</th>
                <th>POSSÍVEIS CONSTATAÇÕES</th>
                <th>PROCEDIMENTOS</th>
                <th>DETALHAMENTO DOS PROCEDIMENTOS</th>
                <th>TÉCNICAS</th>
              </tr>

              <tr *ngFor="let auxQuestao of model.matrizPlanejamentoQuestao">
                <td>
                  <p>{{ auxQuestao.questao.nome }}</p>
                  <p></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.classificacao"></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.descricao"></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.legislacaoAplicavelCriterio"></p>
                </td>
                <td>
                  <p *ngFor="let auxConstatacao of auxQuestao.questao.questaoConstatacao">
                    {{auxConstatacao.constatacao.nome}}
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    {{auxProcedimentoAuditoria.procedimentoAuditoria.nome}}
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    <a class="e2e-inner-html-bound"
                      [innerHTML]="auxProcedimentoAuditoria.procedimentoAuditoria.detalhamentoProcedimento"></a>
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxQuestaoProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    <a
                      *ngFor="let auxTecnicaAuditoria of auxQuestaoProcedimentoAuditoria.procedimentoAuditoria.procedimentoTecnicaAuditoria">
                      <a class="e2e-inner-html-bound" [innerHTML]="auxTecnicaAuditoria.tecnicaAuditoria.nome"></a><br />
                    </a>
                  </p>
                </td>
              </tr>

              <tr *ngFor="let auxQuestao of model.matrizPlanejamentoQuestaoOsa">
                <td>
                  <p>{{ auxQuestao.questao.nome }}</p>
                  <p></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.classificacao"></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.descricao"></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.legislacaoAplicavelCriterio"></p>
                </td>
                <td>
                  <p *ngFor="let auxConstatacao of auxQuestao.questao.questaoConstatacao">
                    {{auxConstatacao.constatacao.nome}}
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    {{auxProcedimentoAuditoria.procedimentoAuditoria.nome}}
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    <a class="e2e-inner-html-bound"
                      [innerHTML]="auxProcedimentoAuditoria.procedimentoAuditoria.detalhamentoProcedimento"></a>
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxQuestaoProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    <a
                      *ngFor="let auxTecnicaAuditoria of auxQuestaoProcedimentoAuditoria.procedimentoAuditoria.procedimentoTecnicaAuditoria">
                      <a class="e2e-inner-html-bound" [innerHTML]="auxTecnicaAuditoria.tecnicaAuditoria.nome"></a><br />
                    </a>
                  </p>
                </td>
              </tr>

              <tr *ngFor="let auxQuestao of model.matrizPlanejamentoQuestaoPlanejamento">
                <td>
                  <p>{{ auxQuestao.questao.nome }}</p>
                  <p></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.classificacao"></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.descricao"></p>
                </td>
                <td>
                  <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.legislacaoAplicavelCriterio"></p>
                </td>
                <td>
                  <p *ngFor="let auxConstatacao of auxQuestao.questao.questaoConstatacao">
                    {{auxConstatacao.constatacao.nome}}
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    {{auxProcedimentoAuditoria.procedimentoAuditoria.nome}}
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    <a class="e2e-inner-html-bound"
                      [innerHTML]="auxProcedimentoAuditoria.procedimentoAuditoria.detalhamentoProcedimento"></a>
                  </p>
                </td>
                <td>
                  <p *ngFor="let auxQuestaoProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                    <a
                      *ngFor="let auxTecnicaAuditoria of auxQuestaoProcedimentoAuditoria.procedimentoAuditoria.procedimentoTecnicaAuditoria">
                      <a class="e2e-inner-html-bound" [innerHTML]="auxTecnicaAuditoria.tecnicaAuditoria.nome"></a><br />
                    </a>
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </div>
    </div>
  </div>
</app-print-layout>