import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IFormulaRiscoResidual } from 'src/app/models/formula-risco-residual.model';
import { FormulaRiscoResidualService } from 'src/app/services/formula-risco-residual.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-formula-risco-residual',
  templateUrl: './formula-risco-residual.component.html',
  styleUrls: ['./formula-risco-residual.component.scss']
})
export class FormulaRiscoResidualComponent extends BaseFormComponent implements AfterViewInit, OnInit {

  model = {} as IFormulaRiscoResidual;
  anoSelecionado: number;
  txtBtnConcluir: string;
  campoObrigatorio = false;
  atualizando = false;
  riscoInerente: any
  avaliacaoControle: any

  textoModal = 'Editar Fórmula do Risco Residual - RR'

  operadorList: any[] = [
    { id: '+', nome: 'Soma (+)' },
    { id: '/', nome: 'Divisão (/)' },
    { id: '*', nome: 'Multiplicação (*)' },
    { id: '-', nome: 'Subtração (-)' }
  ];

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<FormulaRiscoResidualComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private serviceFormulaRiscoResidual: FormulaRiscoResidualService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.model.id = data.id;
    this.model.campo_1 = data?.campo_1;
    this.model.campo_2 = data?.campo_2;
    this.model.operador = data?.operador;
    this.model.ativo = data?.ativo;
    this.model.criteriosAvaliacaoRiscosId = data?.criteriosAvaliacaoRiscosId;
    this.riscoInerente = data?.riscoInerente;
    this.avaliacaoControle = data?.avaliacaoControle;

    if (!this.model.id || this.model.id == 0) {
      this.atualizando = false;
      this.txtBtnConcluir = "Salvar"
    }
    else {
      this.atualizando = true;
      this.txtBtnConcluir = "Salvar"
      this.textoModal = 'Editar Fórmula do Risco Residual - RR'
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  concluir() {
    if (this.atualizando) {
      this.serviceFormulaRiscoResidual.atualizar(this.model).then((res) => { this.dialogRef.close(true); })
    } else {
      this.serviceFormulaRiscoResidual.inserir(this.model).then((res) => { this.dialogRef.close(true); })
    }
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
