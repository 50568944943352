<div [ngClass]="{'emAprovacao': removeMinuta}">
    <div class="content-header no-print">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item"></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <app-print-layout (cancelar)="backClicked()">
        <div class="content">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-11">
                        <div class="card card-outline card-lightblue">
                            <div class="card-header no-print">
                                <h3 class="card-title mb-0 ">
                                    <i *ngIf="this.id" class="card-title mb-0"></i>
                                    Relatoria - {{tipoTrabalhoDescricao}} - {{dadosObtidos?.nomeDocumento}}
                                </h3>
                            </div>
                            <form class="px-3">
                                <app-relatoria [impressao]="true" [aba]="5" visualizar="true"
                                    [dadosObtidos]="dadosObtidos" [relatoriaDados]="relatoriaDados"
                                    [visualizarRelatoriaHidden]="visualizarRelatoriaHidden">
                                </app-relatoria>
                            </form>
                            <div>
                                <div *ngFor="let element of datas; let i = index">
                                    <div *ngFor="let item of element.acoes">
                                        <div class="final-section px-3">
                                            <p *ngIf="item.acao?.codigo === 3">
                                                <span>{{ item.pessoa?.nome }}</span><br>
                                                <span>Elaborador</span>
                                            </p>

                                            <p *ngIf="item.acao?.codigo === 1">
                                                <span>{{ item.pessoa?.nome }}</span><br />
                                                <span>{{ item.pessoa?.funcao?.descricao }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-print-layout>
</div>