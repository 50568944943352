<div class="card-body p-0">
  <form [formGroup]="form" #f="ngForm" >
  <div class="row" *ngIf="tipo === 'osa'">

    <div class="form-group col-md-4">
      <label for="matriz" class="mb-1">Prazo de entrega da Matriz</label>
      <input  [attr.disabled]="visualizar ? true : null" type="text" [(ngModel)]="cronograma.prazoEntregaMatriz" formControlName="prazoEntregaMatriz" class="form-control form-control-sm" bsDatepicker
      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"  placeholder="DD/MM/AAAA" [ngClass]="{ 'is-invalid': submitted && form.controls['prazoEntregaMatriz'].invalid }"/>
      <div *ngIf="submitted && form.controls['prazoEntregaMatriz'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['prazoEntregaMatriz'].errors.required">Campo obrigatório</div>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label for="auditado" class="mb-1">Prazo para resposta do Auditado</label>
      <input  [attr.disabled]="visualizar ? true : null" [(ngModel)]="cronograma.prazoRespostaAuditado" formControlName="prazoRespostaAuditado"  type="text" class="form-control form-control-sm" bsDatepicker
      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"  placeholder="DD/MM/AAAA" [ngClass]="{ 'is-invalid': submitted && form.controls['prazoRespostaAuditado'].invalid }"/>
      <div *ngIf="submitted && form.controls['prazoRespostaAuditado'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['prazoRespostaAuditado'].errors.required">Campo obrigatório</div>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label for="relatorio" class="mb-1">Prazo para entrega do Relatório</label>
      <input  [attr.disabled]="visualizar ? true : null" [(ngModel)]="cronograma.prazoEntregaRelatorio" formControlName="prazoEntregaRelatorio" type="text" class="form-control form-control-sm" bsDatepicker
      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"  placeholder="DD/MM/AAAA" [ngClass]="{ 'is-invalid': submitted && form.controls['prazoEntregaRelatorio'].invalid }"/>
      <div *ngIf="submitted && form.controls['prazoEntregaRelatorio'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['prazoEntregaRelatorio'].errors.required">Campo obrigatório</div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="tipo === 'osa'">
    <div class="form-group col-md-6">
      <label for="duracao">Total de duração</label>
      <input disabled type="text" class="form-control form-control-sm" [ngModel]="cronograma?.quantidadeDiasPrevisao" [ngModelOptions]="{standalone: true}"/>
    </div>
    <div class="form-group col-md-6">
      <label for="execucao">Duração da execução</label>
      <input disabled type="text" class="form-control form-control-sm" [ngModel]="cronograma?.quantidadeDiasExecucao" [ngModelOptions]="{standalone: true}"/>
    </div>
  </div>
  </form>
  <div class="row" *ngIf="tipo === 'planejamento'">
    <div class="form-group col-md-3">
      <label for="duracaoUteis">Duração (dias úteis)</label>
      <input disabled type="text" class="form-control form-control-sm" [ngModel]="cronograma?.quantidadeDiasPrevisao" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  <div *ngIf="tipo === 'osa'" class="form-group col-md-12 p-0 " style="text-align: end;">
    <button *ngIf="!visualizar" type="button" class="btn btn-primary btn-sm " (click)="salvar()">
      <i class="far fa-save"></i> Salvar
      </button>
      <hr />
  </div>

  <div class="card-body p-0">
    <h3 class="m-4">Listagem de fases</h3>
    <table class="table table-hover ">
      <tbody *ngFor="let fase of model; let i = index">
        <tr aria-expanded="false">
          <td>
            <div>
              <p class="nome m-0"><a class="fa fas fa-plus" type="button" *ngIf="fase.atividades[0]"
                  data-toggle="collapse" href="#collapseOne-{{i}}"></a><b>ﾠFase:ﾠ</b>ﾠ{{ fase.nome }}
              </p>
              <p class="btn btn-success bg-transparent btn-xs text-left left-dias">
                <b>Dias Planejados:ﾠ</b> {{ fase.quantidadeDias }}
              </p>

              <button [hidden]="visualizar || hiddenEdicao" *ngIf="tipo === 'planejamento'" type="button" class="btn btn-success btn-xs text-left left"
                (click)="modalIncluir(fase)">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr class="expandable-body">
          <td>
            <div class="p-0" id="collapseOne-{{i}}">
              <table class="table table-hover">
                <tbody *ngFor="let atividade of fase.atividades; let i = index">
                  <tr>
                    <td>
                      <div>
                        <p class="nome m-0"><a class="fa fas fa-plus" type="button" data-toggle="collapse"
                            href="#collapseTwo-{{i}}" *ngIf="atividade.subAtividades[0]"></a><b>ㅤAtividade: ﾠ</b>ﾠ{{
                          atividade.nome }}</p>
                        <p class="btn btn-success bg-transparent btn-xs text-left left-dias">
                          <b>Dias Planejados:ﾠ</b>{{ atividade.quantidadeDias }}
                        </p>

                        <label *ngIf="tipo === 'osa'" for="diasRealizacao" class="diasRealizacao">Dias de
                          Realização:</label>
                        <input [ngClass]="{'input': !atividade.selecionado}" *ngIf="tipo === 'osa'" type="text" [(ngModel)]="atividade.quantidadeDiasRealizado"
                          onkeypress=" event.charCode >= 48 && event.charCode <= 57"
                          [disabled]="!atividade.selecionado" class="diasRealizado" maxlength="10"/>
                        <button [hidden]="!homologado" type="button" class="lapis" *ngIf="tipo === 'osa'"
                          (click)="atividade.selecionado = !atividade.selecionado">
                          <i class="fa fa-pen"></i>
                        </button>
                        <button [hidden]="!homologado" type="button" class="check" *ngIf="tipo === 'osa'" [hidden]="!atividade.selecionado" (click)="salvarOsaAtividade(atividade)">
                          <i class="fa fa-check"></i>
                        </button>

                        <button [hidden]="!homologado" type="button" class="btn btn-primary btn-xs text-left left ml-2"
                          *ngIf="tipo === 'planejamento'" (click)="modalIncluir(atividade, 'editar')">
                          <i class="fa fa-pen"></i>
                        </button>
                        <button [hidden]="!homologado" type="button" class="btn btn-danger btn-xs text-left left ml-4"
                          *ngIf="tipo === 'planejamento'" (click)="excluirAtividade(atividade.id)">
                          <i class="fa fa-trash-alt"></i>
                        </button>
                        <button [hidden]="!homologado" type="button" class="btn btn-success btn-xs text-left left"
                          *ngIf="tipo === 'planejamento'" (click)="modalIncluir(atividade, 'incluir')">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr class="expandable-body">
                    <td>
                      <div class="p-0" id="collapseTwo-{{i}}">
                        <table class="table table-hover">
                          <tbody *ngFor="let subAtividade of atividade.subAtividades, let i = index">
                            <tr>
                              <td>
                                <div>
                                  <p class="nome m-0">ﾠ{{ subAtividade.nome }}</p>
                                  <p class="btn btn-success bg-transparent btn-xs text-left left-dias">
                                    <b>Dias Planejados:ﾠ</b>
                                    {{ subAtividade.quantidadeDias }}
                                  </p>
                                  <label *ngIf="tipo === 'osa'" for="diasRealizacao" class="diasRealizacao">Dias de
                                    Realização:</label>
                                  <input [ngClass]="{'input': !subAtividade.selecionado}" *ngIf="tipo === 'osa'" type="text" [disabled]="!subAtividade.selecionado"
                                    [(ngModel)]="subAtividade.quantidadeDiasRealizado" maxlength="10"
                                    onkeypress=" event.charCode >= 48 && event.charCode <= 57"
                                    class="diasRealizado" />
                                  <button [hidden]="visualizar" type="button" class="lapis" *ngIf="tipo === 'osa'"
                                    (click)="subAtividade.selecionado = !subAtividade.selecionado">
                                    <i class="fa fa-pen"></i>
                                  </button>
                                  <button [hidden]="visualizar" type="button" class="check" *ngIf="tipo === 'osa'" [hidden]="!subAtividade.selecionado" (click)="salvarOsaSubAtividade(subAtividade)">
                                    <i class="fa fa-check"></i>
                                  </button>

                                  <button [hidden]="visualizar" type="button" *ngIf="tipo === 'planejamento'"
                                    class="btn btn-primary btn-xs text-left left ml-2" (click)="
                                      modalIncluir(subAtividade, 'editarSub')
                                    ">
                                    <i class="fa fa-pen"></i>
                                  </button>
                                  <button [hidden]="visualizar" type="button" *ngIf="tipo === 'planejamento'"
                                    class="btn btn-danger btn-xs text-left left ml-4"
                                    (click)="excluirSubAtividade(subAtividade.id)">
                                    <i class="fa fa-trash-alt"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
